import React, { Component, Fragment } from 'react';
import { Button, Form, Header, Segment } from 'semantic-ui-react';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { saveAuthUser } from '../../authUser/authUserActions';
import { confirm } from '../../../app/functionModals/functionModalsActions';
import UserEditDetailsFields, { validate } from './UserEditDetailsFields';


const mapState = state => ({
  loading: state.authUser.loading,
  submitting: state.authUser.submitting,
  initialValues: state.authUser.user
});

const actions = {
  saveAuthUser,
  confirm
};

class UserEditDetails extends Component {
  async componentDidMount() {
    // Don't do anyc - load concurrently
    // this.props.loadAuthUserTutors();
    // this.props.loadAuthUserGroups();
    // this.props.loadEducations();
  }

  isLoading = () => {
    const {
      loading,
    } = this.props;
    if (loading) {
      return true;
    }
    // if (tutors.length === 0) {
    //   return true;
    // }
    // if (educations.length === 0) {
    //   return true;
    // }
    return false;
  };

  handleCancel = () => {
    const { reset } = this.props;
    this.props.confirm(
      'Are you sure you want to cancel your changes?',
      {
        onOk: () => {
          reset();
        }
      },
      'Yes',
      'No'
    );
  };

  handleSaveDetails = async values => {
    try {
      await this.props.saveAuthUser(values);
      this.reset();
    } catch (error) {
      // An error occured - the toast was thrown in the action
    }
  };

  render() {
    const {
      handleSubmit,
      submitting,
      invalid,
      pristine,
      initialValues
    } = this.props;
    return (
      <Segment.Group>
        <Segment inverted color='red'>
          <Header>My Details</Header>
        </Segment>
        { this.isLoading() && (
          <Segment attached style={ { minHeight: 418 } }>
            <LoadingComponent/>
          </Segment>
        ) }
        { !this.isLoading() && (
          <Fragment>
            <Form
              autoComplete='off'
              onSubmit={ handleSubmit(this.handleSaveDetails) }
            >
              <Segment attached>
                <UserEditDetailsFields
                  forList={ false }
                  initialValues={ initialValues }
                />
              </Segment>
              <Segment attached secondary style={ { height: 70 } }>
                <Button.Group floated='right'>
                  <Button
                    disabled={ invalid || submitting || pristine }
                    loading={ submitting }
                    size='large'
                    color='red'
                    icon='save'
                    content='Save'
                    style={ { width: 120 } }
                  />
                  <Button
                    disabled={ submitting || pristine }
                    as='a'
                    onClick={ this.handleCancel }
                    size='large'
                    icon='close'
                    content='Cancel'
                    style={ { width: 120 } }
                  />
                </Button.Group>
              </Segment>
            </Form>
          </Fragment>
        ) }
      </Segment.Group>
    );
  }
}

export default compose(
  connect(
    mapState,
    actions
  ),
  reduxForm({
    form: 'userEditForm',
    validate,
    enableReinitialize: true,
    destroyOnUnmount: false
  })
)(UserEditDetails);
