import { createReducer } from '../../../../app/store/createReducer';
import {
  ACTION_LOADING,
  ACTION_LOADING_COMPLETED,
  ACTION_LOADINGITEM,
  ACTION_LOADINGITEM_COMPLETED,
  ACTION_SUBMITTING,
  ACTION_SUBMITTING_COMPLETED,
  LOAD_ACTION,
  LOAD_ACTION_DETAILS
} from './byyAdminActionsConstants';


const initialState = {
  loading: false,
  loadingItem: false,
  submitting: false,
  submittingKey: null,
  actions: [],
  onlyActive: true,
  action: null
};

const loading = (state, payload) => {
  return {
    ...state,
    loading: true
  };
};

const loadingCompleted = (state, payload) => {
  return {
    ...state,
    loading: false
  };
};

const loadingItem = (state, payload) => {
  return {
    ...state,
    loadingItem: true
  };
};

const loadingItemCompleted = (state, payload) => {
  return {
    ...state,
    loadingItem: false
  };
};

const submitting = (state, payload) => {
  return {
    ...state,
    submitting: true,
    submittingKey: null
  };
};

const submittingCompleted = (state, payload) => {
  return {
    ...state,
    submitting: false,
    submittingKey: null
  };
};

const loadActions = (state, payload) => {
  return {
    ...state,
    actions: payload.Actions
  };
};

const loadActionDetails = (state, payload) => {
  return {
    ...state,
    action: payload
  };
};

// const updatePerson = (state, payload) => {
//   const currentDetails = state.details
//     .slice()
//     .filter(p => p.PersonID !== payload.user.PersonID);
//   const details = [...currentDetails, payload.user].sort((a, b) =>
//     `${ a.FirstName.toUpperCase() } ${ a.LastName.toUpperCase() }` > `${ b.FirstName.toUpperCase() } ${ b.LastName.toUpperCase() }` ? 1 : -1
//   );
//   return {
//     ...state,
//     details
//   };
// };

export default createReducer(initialState, {
  [ACTION_LOADING]: loading,
  [ACTION_LOADING_COMPLETED]: loadingCompleted,
  [ACTION_LOADINGITEM]: loadingItem,
  [ACTION_LOADINGITEM_COMPLETED]: loadingItemCompleted,
  [ACTION_SUBMITTING]: submitting,
  [ACTION_SUBMITTING_COMPLETED]: submittingCompleted,
  [LOAD_ACTION]: loadActions,
  [LOAD_ACTION_DETAILS]: loadActionDetails,
  // [UPDATE_ACTIONPERSON]: updateAction
});
