import React, { Component, Fragment } from 'react';
import { Container, Dropdown, DropdownMenu, Icon, Image, Menu } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isLoggedIn, loadVersion, logout, setRoleUrl } from '../../auth/authActions';
import { loadAuthUser, loadPushNotificationSettings } from '../../authUser/authUserActions';
import { NavLink } from 'react-router-dom';
import { alert, confirm } from '../../../app/functionModals/functionModalsActions';
import { version as reactVersion } from '../../../version';


const mapState = state => ({
  displayName: state.authUser.user.DisplayName,
  pushNotification: state.authUser.pushNotification,
  version: state.auth.version
});

const actions = {
  loadPushNotificationSettings,
  loadAuthUser,
  loadVersion,
  logout,
  confirm,
  alert
};

class NavBar extends Component {
  async componentDidMount() {
    this.props.loadVersion();
    await this.props.loadAuthUser();
    if (isLoggedIn()) {
      // await this.props.loadPushNotificationSettings();
    }
  }

  componentWillUnmount() {
  }

  logOut = () => {
    // toastr.confirm('Are you sure you want to logout?', {
    //   //component: () => (<div style={{padding: '10px', color: 'red'}}>are you <b>sure?</b></div>),
    //   okText: 'Logout',
    //   onOk: async () => {
    //     this.props.logout();
    //     this.props.history.push('/');
    //   }
    // });
    this.props.confirm(
      'Are you sure you want to logout?',
      {
        onOk: async () => {
          await this.props.logout();
          this.props.history.push('/');
        }
      },
      'Logout'
    );
  };

  showOldVersionWarning = () => {
    this.props.alert(
      '<div style="float:left;"><i class="orange warning circular inverted icon"/></div><div style="color:darkorange;float:left;width:90%;padding-left:10px;">You are using an outdated version.<br /><br />Please close all instances of BYY Admin (in all browsers too), and then re-open.</div>'
    );
  };

  render() {
    const {
      displayName,
      pushNotification,
      version
    } = this.props;
    const role = setRoleUrl();
    // todo check this in api and web
    let oldVersion = version !== null && version.VersionNo !== reactVersion();
    if (version !== null && oldVersion) {
      //console.log(version.VersionCheckDate.toISOString() < new Date());
      oldVersion = version.VersionCheckDate < new Date().toISOString();
    }
    return (
      <Menu inverted fixed='top'>
        <Container fluid>
          <Menu.Item style={ { textAlign: 'left !important' } }>
            <Image
              height={ 40 }
              // TODO Change this image
              src='/assets/logo192.png'
              // style={{ border: '1px solid black', borderRadius: '4px' }}
            />
          </Menu.Item>
          { isLoggedIn() && (
            <Fragment>
              { oldVersion && (
                <Menu.Item as='a' onClick={ this.showOldVersionWarning }>
                  <Icon
                    name='warning'
                    circular
                    color='orange'
                    inverted
                    title='Click to view warning'
                  />
                </Menu.Item>
              ) }
              { pushNotification && (
                <iframe
                  src={ `${ pushNotification.pushurl }?personID=${ pushNotification.PersonID }&personVer=${ pushNotification.PersonVer }&url=${ pushNotification.url }%2Fapi%2FPush%2FSubscribe` }
                  title='push'
                  style={ { display: 'none' } }
                />
              ) }
            </Fragment>
          ) }
          <Menu.Item position='right'>
            <Icon
              name='user outline'
              circular
              color='grey'
              inverted
              title='Click to view warning'
            />
            <Dropdown pointing='top left' text={ displayName }>
              <DropdownMenu>
                <Dropdown.Item
                  as={ NavLink }
                  to={ `/${ role.toLowerCase() }/userdetails` }
                  text='My Details'
                  icon='user'
                />
                <Dropdown.Item
                  onClick={ this.logOut }
                  text='Log Out'
                  icon='power'
                />
              </DropdownMenu>
            </Dropdown>
          </Menu.Item>
        </Container>
      </Menu>
    );
  }
}

export default compose(
  withRouter,
  connect(
    mapState,
    actions
  )
)(NavBar);
