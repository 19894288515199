import {
  LOAD_USERGRIDPEOPLE,
  LOAD_USERGRIDPEOPLE_PERSON,
  UPDATE_USERGRIDPEOPLEPERSON,
  USERGRIDPEOPLE_LOADING,
  USERGRIDPEOPLE_LOADING_COMPLETED,
  USERGRIDPEOPLE_LOADINGITEM,
  USERGRIDPEOPLE_LOADINGITEM_COMPLETED,
  USERGRIDPEOPLE_SUBMITTING,
  USERGRIDPEOPLE_SUBMITTING_COMPLETED
} from './userGridPeopleConstants';
import { httpGet, httpPost } from '../../../../app/common/utils/http';
import { toastr } from 'react-redux-toastr';
import { convertDate } from "../../../../app/common/utils/common";


export const loadUserGridPeople = (
  onlyActive,
  setLoading = true
) => async (dispatch, getState) => {
  if (setLoading) {
    await dispatch({ type: USERGRIDPEOPLE_LOADING });
  }
  try {
    const response = await httpGet(
      `Admin/GetAllPersons`
    );
    await dispatch({
      type: LOAD_USERGRIDPEOPLE,
      payload: {
        ...response
      }
    });
  } catch (error) {
    toastr.error('Error', 'There was a problem getting all mothers');
    throw error;
  } finally {
    await dispatch({ type: USERGRIDPEOPLE_LOADING_COMPLETED });
  }
};


export const loadUserGridPerson = personID => async (
  dispatch,
  getState
) => {
  await dispatch({ type: USERGRIDPEOPLE_LOADINGITEM });
  try {
    const response = await httpGet(`Admin/GetPerson?PersonID=${ personID }`);
    await dispatch({
      type: LOAD_USERGRIDPEOPLE_PERSON,
      payload: {
        ...response
      }
    });
  } catch (error) {
    toastr.error('Error', 'There was a problem getting the persons details');
    throw error;
  } finally {
    await dispatch({ type: USERGRIDPEOPLE_LOADINGITEM_COMPLETED });
  }
};


export const savePersonDetails = (user) => async (
  dispatch,
  getState
) => {
  // await dispatch({
  //   type: USERGRIDPEOPLE_SUBMITTING,
  //   // payload: { submittingKey: 'ES' }
  // });

  try {
    // Clean up formatting before saving
    user.ExpectedDueDate = convertDate(user.ExpectedDueDate);
    user.PersonChildDeliveryDt = convertDate(user.PersonChildDeliveryDt);
    user.BBDateOfBirth = convertDate(user.BBDateOfBirth);

    user.CellNumber = user.CellNumber.replace(/\s/g, "").replace(/[{()}]/g, "").replace(/-/g, "");
    user.BBCellNumber = user.BBCellNumber.replace(/\s/g, "").replace(/[{()}]/g, "").replace(/-/g, "");

    let response = await httpPost(`Admin/SavePersonDetails`, {
      Details: user
    });
    toastr.success('Success', 'The persons details were saved');

    await dispatch({
      type: LOAD_USERGRIDPEOPLE_PERSON,
      payload: {
        ...response
      }
    });

    await dispatch({
      type: UPDATE_USERGRIDPEOPLEPERSON,
      payload: {
        user: response.Details
      }
    });

  } catch (error) {
    toastr.error('Error', error);
    throw error;
  } finally {
    await dispatch({ type: USERGRIDPEOPLE_SUBMITTING_COMPLETED });
  }
};

export const updatePersonStatus = user => async (dispatch, getState) => {
  await dispatch({
    type: USERGRIDPEOPLE_SUBMITTING,
    payload: { submittingKey: 'SS' }
  });
  try {
    await httpPost(`Person/UpdatePersonStatus`, {
      ...user
    });
    toastr.success('Success', 'The persons status was updated');
    await dispatch({
      type: UPDATE_USERGRIDPEOPLEPERSON,
      payload: {
        user
      }
    });
  } catch (error) {
    toastr.error('Error', error);
    throw error;
  } finally {
    await dispatch({ type: USERGRIDPEOPLE_SUBMITTING_COMPLETED });
  }
};
