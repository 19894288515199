import React, { Component, createRef } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { confirm, inputBox } from '../../../app/functionModals/functionModalsActions';
import { openModal } from '../../../app/modals/modalActions';
import ReactTooltip from 'react-tooltip';
import DashboardActionsSearchSortAndRefresh from "../../common/dashboardActions/DashboardActionsSearchSortAndRefresh";
import { loadActions } from "./data/byyAdminActionsActions";
import BYYAdminActionTableGroup from "./BYYAdminActionTableGroup";
import LoadingComponent from "../../../app/layout/LoadingComponent";


const mapState = state => ({
  initialized: state.initialized,
  loading: state.loading,
  submitting: state.submitting,
  submittingKey: state.submittingKey,
  action: state.actions
});

const actions = {
  loadActions,
  openModal,
  confirm,
  inputBox
};


class BYYAdminActions extends Component {
  state = {
    searchText: '',
    sortValue: 'A',
    currentPage: 1,
    onlyActive: this.props.onlyActive,
    action: null,
    column: null,
    direction: null,
  };

  headerActionsRef = createRef();
  scrollRef = createRef();

  loadActions = async (onlyActive, setLoading) => {
    await this.props.loadActions(
      onlyActive,
      setLoading
    );
    ReactTooltip.rebuild();
  };

  refreshActions = async () => {
    await this.props.loadActions(this.state.onlyActive, true);
  };

  showHeaderActions = () => {
    this.headerActionsRef.current.classList.add('header-actions-focus');
  };

  hideHeaderActions = () => {
    if (this.state.searchText === '') {
      this.headerActionsRef.current.classList.remove('header-actions-focus');
    }
  };

  searchAction = searchText => {
    this.setState({
      searchText
    });
  };

  changeOnlyActive = onlyActive => {
    this.setState({
      onlyActive
    });
    // this.loadCenterPeople(onlyActive, true);
  };

  editAction = action => {
    this.props.openModal('BYYActionEditModal', {
      ActionID: action.ActionID
      // updatedPerson: this.updatedPerson
    });
  };

  sort = sortValue => {
    this.setState({
      sortValue
    });
  };

  render() {
    // const { submitting } = this.props;
    // const {
    //   onlyActive,
    //   searchText,
    //   person
    // } = this.state;

    const {
      initialized = true,
      loading,
      // submitting,
      // submittingKey
    } = this.props;

    const {
      onlyActive,
      searchText,
      // column,
      // direction,
      // action
    } = this.state;

    return (
      <Segment.Group
        onMouseMove={ () => this.showHeaderActions() }
        onMouseLeave={ () => this.hideHeaderActions() }
      >
        <Segment inverted color='red' style={ { height: '50px' } }>
          <Header floated='left'>Actions</Header>

          <DashboardActionsSearchSortAndRefresh
            headerActionsRef={ this.headerActionsRef }
            refreshText='Refresh Actions.'
            refresh={ this.refreshActions }
            changeOnlyActive={ this.changeOnlyActive }
            onlyActive={ onlyActive }
            search={ this.searchAction }
            placeholderText='Search Actions...'
          />

        </Segment>
        <Segment attached>
          { (!initialized || loading) && <LoadingComponent/> }
          { initialized && (
            <BYYAdminActionTableGroup
              setFiles={ this.setFiles }
              searchText={ searchText }
              onlyActive={ onlyActive }
              editAction={ this.editAction }
              // TODO remove changeStatus as we have no other action
              changeStatus={ this.changeStatus }
              scrollRef={ this.scrollRef }
            />
          ) }
        </Segment>
      </Segment.Group>
    );
  }
}

export default compose(
  connect(
    mapState,
    actions
  )
)(BYYAdminActions);
