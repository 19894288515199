import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Message, Segment, Table } from 'semantic-ui-react';
import Paging from "../../../app/common/utils/Paging";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { isMobileView } from "../../../app/common/utils/common";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";

import BYYAdminActionItem from './BYYAdminActionItem';
import { loadActions } from '../byyAdminActions/data/byyAdminActionsActions';
import _ from "lodash";


const mapState = state => ({
  loading: state.action.loading,
  actions: state.action.actions,
  onlyActive: state.action.onlyActive
});

const actions = {
  loadActions
};

const itemsPerPage = 10;
let totalPages = 0;

class BYYAdminActionTableGroup extends Component {
  state = {
    currentPage: 1
  };

  async componentDidMount() {
    await this.props.loadActions(this.props.onlyActive);
  }

  getActions = () => {
    let { actions, searchText } = this.props;

    if (searchText !== '') {
      searchText = searchText.replace(/["'()-]/g, '').replace(/ /g, '');
      const re = new RegExp(searchText, 'gi');

      actions = actions.filter(
        a =>
          a.MothersCellNum.match(re) ||
          a.MothersName.match(re) ||
          a.MothersSurname.match(re) ||
          a.ActionTypeCode.match(re)
      );
    }

    let action = actions.filter(d => d.CompletedInd === 0);
    action = [...action, ...actions.filter(d => d.CompletedInd === 1)];

    const newTotalPages = Math.ceil(action.length / itemsPerPage);

    if (newTotalPages !== totalPages) {
      this.setState({ currentPage: 1 });
    }

    totalPages = newTotalPages;

    const page = action.slice(
      (this.state.currentPage - 1) * itemsPerPage,
      this.state.currentPage * itemsPerPage
    );

    return page;
  };

  countActionsComplete = completedInd => {
    const { actions } = this.props;
    return actions.filter(
      a =>
        a.CompletedInd === completedInd
    ).length;
  };

  pageChange = async (activePage, isTop) => {
    this.setState({
      currentPage: activePage
    });
    if (!isTop) {
      window.scrollTo(0, 0);
    }
  };

  handleSort = (clickedColumn) => () => {
    const { column, actions, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        actions: _.sortBy(actions, [clickedColumn]),
        direction: 'ascending',
      });

      return
    }

    this.setState({
      actions: actions.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    })
  };

  render() {
    const {
      loading,
      setFiles,
      editAction,
      changeStatus,
      scrollRef,
      actions
    } = this.props;

    const {
      currentPage,
      column,
      direction
    } = this.state;

    const countComplete = this.countActionsComplete(1);
    const countIncomplete = this.countActionsComplete(0);

    return (
      <Fragment>
        {/* LABELS*/ }
        { !loading && (
          <Segment>
            <Label
              color='green'
              basic
              style={ { marginRight: 10, marginBottom: isMobileView() ? 10 : 0 } }
            >
              { countComplete } Completed { countComplete === 1 ? '' : '' }
            </Label>
            <Label
              color='red'
              basic
              style={ { marginRight: 10, marginBottom: isMobileView() ? 10 : 0 } }
            >
              { countIncomplete } Incomplete{ countIncomplete === 1 ? '' : '' }
            </Label>
          </Segment>
        ) }
        {/* PAGING*/ }
        { !loading && totalPages !== 1 && actions.length > 0 && (
          <div style={ { textAlign: 'center' } }>
            <Paging
              totalPages={ totalPages }
              currentPage={ currentPage }
              onPageChange={ this.pageChange }
              isTop
            />
          </div>
        ) }
        {/* NO ACTIONS*/ }
        { !loading && actions.length === 0 && (
          <Message>
            <Message.Header>No Actions Avaialble</Message.Header>
          </Message>
        ) }
        <br/>
        {/* TABLE ACTIONS*/ }
        <div style={ { minHeight: 400 } }>
          { loading && <LoadingComponent/> }
          { !loading && (
            <Table celled striped fixed style={ { minHeight: 400 } }>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    sorted={ column === '>MothersDetails' ? direction : null }
                    onClick={ this.handleSort('MothersDetails') }
                  >
                    Mothers Details
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={ column === '>MothersCellNum' ? direction : null }
                    onClick={ this.handleSort('MothersCellNum') }
                  >
                    Cell Number
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={ column === 'ActionTypeCode' ? direction : null }
                    onClick={ this.handleSort('ActionTypeCode') }
                  >
                    Action Type
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={ column === 'ActionStatusCode' ? direction : null }
                    onClick={ this.handleSort('ActionStatusCode') }
                  >
                    Action Status
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={ column === 'DateIssued' ? direction : null }
                    onClick={ this.handleSort('DateIssued') }
                  >
                    Date Issued
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={ column === 'CompletedDate' ? direction : null }
                    onClick={ this.handleSort('DateCompleted') }
                  >
                    Date Completed
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={ column === 'MarkCompeted' ? direction : null }
                    onClick={ this.handleSort('MarkCompleted') }
                  >
                    Mark As Competed
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                { !loading &&
                this.getActions().map(action => {
                  return (
                    <BYYAdminActionItem
                      key={ action.ActionID }
                      action={ action }
                      setFiles={ setFiles }
                      editAction={ editAction }
                      changeStatus={ changeStatus }
                      scrollRef={ scrollRef }
                    />
                  );
                }) }
              </Table.Body>
            </Table>
          ) }
        </div>
        <br/>
        {/* PAGING*/ }
        { !loading && totalPages !== 1 && actions.length > 0 && (
          <div style={ { textAlign: 'center' } }>
            <Paging
              totalPages={ totalPages }
              currentPage={ currentPage }
              onPageChange={ this.pageChange }
            />
          </div>
        ) }
      </Fragment>
    );
  }
}

export default compose(
  connect(
    mapState,
    actions
  )
)(BYYAdminActionTableGroup);
