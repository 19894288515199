export const ACTION_LOADING = 'ACTION_LOADING';
export const ACTION_LOADING_COMPLETED = 'ACTION_LOADING_COMPLETED';
export const ACTION_LOADINGITEM = 'ACTION_LOADINGITEM';
export const ACTION_LOADINGITEM_COMPLETED = 'ACTION_LOADINGITEM_COMPLETED';
export const ACTION_SUBMITTING = 'ACTION_SUBMITTING';
export const ACTION_SUBMITTING_COMPLETED = 'ACTION_SUBMITTING_COMPLETED';
export const LOAD_ACTION = 'LOAD_ACTION';
export const LOAD_ACTION_DETAILS = 'LOAD_ACTION_DETAILS';
export const ACTION_LOADTUTORS = 'ACTION_LOADTUTORS';
export const ACTION_LOADGROUPS = 'ACTION_LOADGROUPS';
export const UPDATE_ACTIONPERSON = 'UPDATE_ACTIONPERSON';