import React, { Component, createRef } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { openModal } from '../../app/modals/modalActions';
import ReactTooltip from 'react-tooltip';
import './UserGridPeople.css';
import UserGridPeopleGroup from './UserGridPeopleGroup';
import { loadUserGridPeople } from '../userGrid/data/userGridPeople/userGridPeopleActions';
import DashboardActionsForAdminPeople from "../common/dashboardActions/DashboardActionsForAdminPeople";


const mapState = state => ({
  submitting: state.submitting,
  onlyActive: state.onlyActive
});

const actions = {
  loadUserGridPeople,
  openModal
};

class UserGridPeople extends Component {
  state = {
    searchText: '',
    onlyActive: this.props.onlyActive,
    files: [],
    person: null
  };

  headerActionsRef = createRef();
  scrollRef = createRef();

  loadUserGridPeople = async (onlyActive, setLoading) => {
    await this.props.loadUserGridPeople(
      onlyActive,
      setLoading
    );
    ReactTooltip.rebuild();
  };


  refreshPeople = async () => {
    await this.props.loadUserGridPeople(this.state.onlyActive, true);
  };

  showHeaderActions = () => {
    this.headerActionsRef.current.classList.add('header-actions-focus');
  };

  hideHeaderActions = () => {
    if (this.state.searchText === '') {
      this.headerActionsRef.current.classList.remove('header-actions-focus');
    }
  };

  searchUser = searchText => {
    this.setState({
      searchText
    });
  };

  changeOnlyActive = onlyActive => {
    this.setState({
      onlyActive
    });
    // this.loadCenterPeople(onlyActive, true);
  };


  setFiles = (files, person) => {
    this.setState({ files, person });
  };

  updatedPerson = async personID => {
    // If it's a new person added - scroll to that new person
    // await this.setState({ personIDAdded: personID });
    if (this.scrollRef) {
      this.scrollRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'nearest'
      });
    }
  };

  editUser = person => {
    this.props.openModal('UserGridPersonEditModal', {
      personID: person.PersonID,
      updatedPerson: this.updatedPerson
    });
  };

  changeStatus = person => {
    this.props.openModal('PersonChangeStatusModal', {
      personID: person.PersonID
    });
  };


  render() {
    // const { submitting } = this.props;
    const {
      onlyActive,
      searchText,
      // person
    } = this.state;
    return (
      <Segment.Group
        onMouseMove={ () => this.showHeaderActions() }
        onMouseLeave={ () => this.hideHeaderActions() }
      >
        <Segment inverted color='red' style={ { height: '50px' } }>
          <Header floated='left'>People</Header>

          <DashboardActionsForAdminPeople
            headerActionsRef={ this.headerActionsRef }
            refreshText='Refresh people.'
            refresh={ this.refreshPeople }
            changeOnlyActive={ this.changeOnlyActive }
            onlyActive={ onlyActive }
            searchUser={ this.searchUser }
          />

        </Segment>

        <UserGridPeopleGroup
          setFiles={ this.setFiles }
          searchText={ searchText }
          onlyActive={ onlyActive }
          editUser={ this.editUser }
          changeStatus={ this.changeStatus }
          scrollRef={ this.scrollRef }
        />
      </Segment.Group>
    );
  }
}

export default compose(
  connect(
    mapState,
    actions
  )
)(UserGridPeople);
