import React from 'react';
import { Button, Icon, Input } from 'semantic-ui-react';
import { isMobileView } from '../../../app/common/utils/common';


const DashboardActionsForAdminPeople = ({
                                          headerActionsRef,
                                          refresh,
                                          refreshText,
                                          searchUser,
                                          changeOnlyActive,
                                          onlyActive
                                        }) => {
  return (
    <div ref={ headerActionsRef } className='header-actions'>
      <div style={ { float: 'left', marginTop: '-6px', marginRight: '-10px' } }>
        <Input
          icon={ isMobileView() ? null : 'search' }
          iconPosition={ isMobileView() ? null : 'left' }
          placeholder='Search users...'
          style={ { width: isMobileView() ? 70 : 200 } }
          onChange={ e => searchUser(e.target.value) }
        />
      </div>
      <div style={ { float: 'right', marginTop: '-6px', marginRight: '-10px' } }>
        {/*<Popup*/ }
        {/*  position='bottom right'*/ }
        {/*  hoverable*/ }
        {/*  style={ { backgroundColor: 'lightcyan' } }*/ }
        {/*  trigger={*/ }
        {/*    <Button basic inverted circular icon>*/ }
        {/*      <Icon name='group'/>*/ }
        {/*    </Button>*/ }
        {/*  }*/ }
        {/*>*/ }
        {/*  <Popup.Header>Show</Popup.Header>*/ }
        {/*  <Popup.Content>*/ }
        {/*    <Divider/>*/ }
        {/*    <div style={ { padding: 4 } }>*/ }
        {/*      <Radio*/ }
        {/*        toggle*/ }
        {/*        label='Active only'*/ }
        {/*        name='ShowType'*/ }
        {/*        value='1'*/ }
        {/*        checked={ onlyActive }*/ }
        {/*        onChange={ () => changeOnlyActive(true) }*/ }
        {/*      />*/ }
        {/*    </div>*/ }
        {/*    <div style={ { padding: 4 } }>*/ }
        {/*      <Radio*/ }
        {/*        toggle*/ }
        {/*        label='All'*/ }
        {/*        name='ShowType'*/ }
        {/*        value='0'*/ }
        {/*        checked={ !onlyActive }*/ }
        {/*        onChange={ () => changeOnlyActive(false) }*/ }
        {/*      />*/ }
        {/*    </div>*/ }
        {/*  </Popup.Content>*/ }
        {/*</Popup>*/ }
        <Button
          basic
          inverted
          circular
          icon
          data-tip={ refreshText }
          onClick={ refresh }
        >
          <Icon name='refresh'/>
        </Button>
      </div>
    </div>
  );
};

export default DashboardActionsForAdminPeople;
