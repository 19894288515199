import React, { Component, createRef } from "react";
import { Header, Segment } from "semantic-ui-react";
import { compose } from "redux";
import { connect } from "react-redux";
import { confirm, inputBox } from "../../../app/functionModals/functionModalsActions";
import { openModal } from "../../../app/modals/modalActions";
import ReactTooltip from "react-tooltip";
import DashboardActionsSearchSortAndRefresh from "../../common/dashboardActions/DashboardActionsSearchSortAndRefresh";
import { loadBenefitsSummary } from "../data/statsActions";
import BenefitsSummaryTableGroup from "./BenefitsSummaryTableGroup";
import LoadingComponent from "../../../app/layout/LoadingComponent";

const mapState = state => ({
  initialized: state.initialized,
  loading: state.loading,
  benefitsSummary: state.loadStats.benifitsSummary
});

const actions = {
  loadBenefitsSummary,
  openModal,
  confirm,
  inputBox
};

class BenefitsSummary extends Component {
  state = {
    searchText: "",
    sortValue: "A",
    currentPage: 1,
    importException: null,
    column: null,
    direction: null
  };

  headerActionsRef = createRef();
  scrollRef = createRef();

  loadBenefitsSummary = async setLoading => {
    await this.props.loadBenefitsSummary(setLoading);
    ReactTooltip.rebuild();
  };

  refreshBenefitsSummary = async () => {
    await this.props.loadBenefitsSummary(true);
  };

  showHeaderActions = () => {
    this.headerActionsRef.current.classList.add("header-actions-focus");
  };

  hideHeaderActions = () => {
    if (this.state.searchText === "") {
      this.headerActionsRef.current.classList.remove("header-actions-focus");
    }
  };

  searchBenefitsSummary = searchText => {
    this.setState({
      searchText
    });
  };

  editAction = action => {
    this.props.openModal("BYYActionEditModal", {
      ActionID: action.ActionID
      // updatedPerson: this.updatedPerson
    });
  };

  sort = sortValue => {
    this.setState({
      sortValue
    });
  };

  render() {
    const { initialized = true, loading } = this.props;

    const { searchText } = this.state;
    return (
      <Segment.Group
        onMouseMove={ () => this.showHeaderActions() }
        onMouseLeave={ () => this.hideHeaderActions() }
      >
        <Segment inverted color="red" style={ { height: "50px" } }>
          <Header floated="left">Benefit Eligibility</Header>

          <DashboardActionsSearchSortAndRefresh
            headerActionsRef={ this.headerActionsRef }
            refreshText="Refresh Benefit Eligibility"
            refresh={ this.refreshBenefitsSummary }
            search={ this.searchBenefitsSummary }
            placeholderText="Search Benefit Eligibility..."
          />
        </Segment>
        <Segment attached>
          { (!initialized || loading) && <LoadingComponent/> }
          { initialized && (
            <BenefitsSummaryTableGroup
              searchText={ searchText }
              scrollRef={ this.scrollRef }
            />
          ) }
        </Segment>
      </Segment.Group>
    );
  }
}

export default compose(connect(mapState, actions))(BenefitsSummary);
