import React, { Fragment } from 'react';
import UserEditDetails from '../../user/userEditDetails/UserEditDetails';
import { Helmet } from 'react-helmet';


const ViewOnlyUserDetails = () => {
  return (
    <Fragment>
      <Helmet>
        <title>View Only - My Details</title>
      </Helmet>
      <UserEditDetails/>
    </Fragment>
  );
};

export default ViewOnlyUserDetails;
