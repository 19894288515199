import { createReducer } from '../../../../app/store/createReducer';
import {
  LOAD_USERGRIDPEOPLE,
  LOAD_USERGRIDPEOPLE_PERSON,
  UPDATE_USERGRIDPEOPLEPERSON,
  USERGRIDPEOPLE_LOADING,
  USERGRIDPEOPLE_LOADING_COMPLETED,
  USERGRIDPEOPLE_LOADINGITEM,
  USERGRIDPEOPLE_LOADINGITEM_COMPLETED,
  USERGRIDPEOPLE_SUBMITTING,
  USERGRIDPEOPLE_SUBMITTING_COMPLETED
} from './userGridPeopleConstants';


const initialState = {
  loading: false,
  loadingItem: false,
  submitting: false,
  submittingKey: null,
  details: [],
  onlyActive: true,
  person: null
};

const loading = (state, payload) => {
  return {
    ...state,
    loading: true
  };
};

const loadingCompleted = (state, payload) => {
  return {
    ...state,
    loading: false
  };
};

const loadingItem = (state, payload) => {
  return {
    ...state,
    loadingItem: true
  };
};

const loadingItemCompleted = (state, payload) => {
  return {
    ...state,
    loadingItem: false
  };
};

const submitting = (state, payload) => {
  return {
    ...state,
    submitting: true,
    submittingKey: payload.submittingKey
  };
};

const submittingCompleted = (state, payload) => {
  return {
    ...state,
    submitting: false,
    submittingKey: null
  };
};

const loadPeople = (state, payload) => {
  return {
    ...state,
    details: payload.Mothers,
    onlyActive: payload.onlyActive
  };
};

const loadPerson = (state, payload) => {
  return {
    ...state,
    person: payload
  };
};

const updatePerson = (state, payload) => {
  const currentDetails = state.details
    .slice()
    .filter(p => p.PersonID !== payload.user.PersonID);
  const details = [...currentDetails, payload.user].sort((a, b) =>
    `${ a.FirstName.toUpperCase() } ${ a.LastName.toUpperCase() }` > `${ b.FirstName.toUpperCase() } ${ b.LastName.toUpperCase() }` ? 1 : -1
  );
  return {
    ...state,
    details
  };
};


export default createReducer(initialState, {
  [USERGRIDPEOPLE_LOADING]: loading,
  [USERGRIDPEOPLE_LOADING_COMPLETED]: loadingCompleted,
  [USERGRIDPEOPLE_LOADINGITEM]: loadingItem,
  [USERGRIDPEOPLE_LOADINGITEM_COMPLETED]: loadingItemCompleted,
  [USERGRIDPEOPLE_SUBMITTING]: submitting,
  [USERGRIDPEOPLE_SUBMITTING_COMPLETED]: submittingCompleted,
  [LOAD_USERGRIDPEOPLE]: loadPeople,
  [LOAD_USERGRIDPEOPLE_PERSON]: loadPerson,
  [UPDATE_USERGRIDPEOPLEPERSON]: updatePerson,
});
