import React from 'react';
import { Table } from 'semantic-ui-react';
import { format } from 'date-fns'
import { formatCell } from "../../../app/common/utils/common";


const ActionStatusCode_Initialised = 'Initialised';
const ActionStatusCode_In_Progress = 'In Progress';
const ActionStatusCode_Attempted_Retry = 'Attempted Retry';
const ActionStatusCode_Completed = 'Completed';
const ActionStatusCode_Failed = 'Failed';

const ImportExceptionItem = ({
                               key,
                               importException,
                               scrollRef
                             }) => {

  function getColor() {
    // Role is type
    let ActionTypeCode;
    switch (ActionTypeCode) {
      case 'Call':
        return 'red';
      case 'BB':
        return 'blue';
      // case 'MM':
      //   return '#ff66c4';
      // case 'B':
      //   return '#38b6ff';
      default:
        return null;
    }
  }

  function getStatusColor() {
    switch (importException.ActionStatusCode) {
      case ActionStatusCode_Initialised:
        return 'blue';
      case ActionStatusCode_In_Progress:
        return 'violet';
      case ActionStatusCode_Attempted_Retry:
        return 'orange';
      case ActionStatusCode_Completed:
        return 'green';
      case ActionStatusCode_Failed:
        return 'red';
      default:
        return 'transparent';
    }
  }

  // function getStatusDescription() {
  //   switch (action.ActionStatusCode) {
  //     case ActionStatusCode_Initialised:
  //       return 'blue';
  //     case ActionStatusCode_Attempted_Retry:
  //       return 'orange';
  //     case ActionStatusCode_Completed:
  //       return 'green';
  //     case ActionStatusCode_Failed:
  //       return 'red';
  //     default:
  //       return '';
  //   }
  // }

  function getFormattedDate(date) {
    return format(new Date(date), 'dd/MM/yyyy');
  }

  return (
    <Table.Row key={ importException.FileErrorLogID }>
      <Table.Cell>
        { importException.FileName }
      </Table.Cell>
      <Table.Cell>
        { formatCell(importException.ErrorRowNumber) }
      </Table.Cell>
      <Table.Cell>
        { importException.ReasonForFailure }
      </Table.Cell>
      <Table.Cell>

        { getFormattedDate(importException.CreatedOnDate) }

      </Table.Cell>


      {/*<Table.Cell>*/ }
      {/*  <Button.Group fluid>*/ }
      {/*    <Button*/ }
      {/*      compact*/ }
      {/*      onClick={ () => editAction(importException) }*/ }
      {/*      basic color={ isAdminUser() ? 'blue' : 'grey' }*/ }
      {/*      content={ isAdminUser() ? 'Edit' : 'View' }*/ }
      {/*      icon={ isAdminUser() ? 'pencil' : 'eye' }*/ }
      {/*    >*/ }
      {/*    </Button>*/ }
      {/*  </Button.Group>*/ }
      {/*</Table.Cell>*/ }
    </Table.Row>
  );
};

export default ImportExceptionItem;
