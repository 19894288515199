import { isLoggedIn } from '../auth/authActions';
import {
  AUTHUSER_CLEAR,
  AUTHUSER_LOAD,
  AUTHUSER_LOAD_PUSHNOTIFICATION,
  AUTHUSER_LOADING,
  AUTHUSER_LOADING_COMPLETED,
  AUTHUSER_SUBMITTING,
  AUTHUSER_SUBMITTING_COMPLETED
} from './authUserConstants';
import { httpGet, httpPost } from '../../app/common/utils/http';
import { toastr } from 'react-redux-toastr';
import jwt_decode from "jwt-decode";


export const loadAuthUser = () => async (dispatch, getState) => {
  if (isLoggedIn()) {
    await dispatch({ type: AUTHUSER_LOADING });
    try {
      // First get from local storage - to avoid delay in loading
      let displayName = localStorage.getItem('displayName');
      await dispatch({
        type: AUTHUSER_LOAD,
        payload: { Details: { DisplayName: displayName } }
      });
      // Now get from the API - which will get the actual values if they were changed in another browser
      const user = jwt_decode(localStorage.getItem('user'));
      const response = await httpGet(`Admin/GetAdminPerson?AdminID=${ user.person }`);
      await dispatch({
        type: AUTHUSER_LOAD,
        payload: response
      });
      // Now update the local store (as the values might have changed)
      localStorage.setItem('displayName', response.Details.DisplayName);
    } catch (error) {
      toastr.error('Error', 'Could not load your details');
    } finally {
      await dispatch({ type: AUTHUSER_LOADING_COMPLETED });
    }
  } else {
    await dispatch({ type: AUTHUSER_CLEAR });
  }
};

export const clearAuthUser = () => async (dispatch, getState) => {
  await dispatch({ type: AUTHUSER_CLEAR });
};


export const saveAuthUser = user => async (dispatch, getState) => {
  await dispatch({ type: AUTHUSER_SUBMITTING });
  try {
    let response = await httpPost(`Admin/SaveAdminDetails`, {
      Details: user
    });
    await dispatch({
      type: AUTHUSER_LOAD,
      payload: { Details: response.Details }
    });
    localStorage.setItem('displayName', response.Details.DisplayName);
    toastr.success('Success', 'Your details have been updated');
  } catch (error) {
    toastr.error('Error', error);
    throw error;
  } finally {
    await dispatch({ type: AUTHUSER_SUBMITTING_COMPLETED });
  }
};


export const loadPushNotificationSettings = () => async (dispatch, getState) => {
  try {
    const pushNotification = await httpGet(`push/GetSubscription`);
    await dispatch({
      type: AUTHUSER_LOAD_PUSHNOTIFICATION,
      payload: {
        pushNotification
      }
    });
  } catch (error) {
    // Don't show an error - just continue
    // toastr.error('Error', "Couldn't get push notification");
  }
};
