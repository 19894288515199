import React, { Component, Fragment } from 'react';
import { loadUserGridPeople } from '../userGrid/data/userGridPeople/userGridPeopleActions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Card, Segment } from 'semantic-ui-react';
import LoadingComponent from '../../app/layout/LoadingComponent';
import UserGridPersonItem from './UserGridPersonItem';
import Paging from '../../app/common/utils/Paging';
import { isMobileView } from "../../app/common/utils/common";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";


const mapState = state => ({
  loading: state.people.loading,
  details: state.people.details,
  onlyActive: state.people.onlyActive
});

const actions = {
  loadUserGridPeople
};

const itemsPerPage = 20;
let totalPages = 0;

class UserGridPeopleGroup extends Component {
  state = {
    currentPage: 1
  };

  async componentDidMount() {
    await this.props.loadUserGridPeople(this.props.onlyActive);
  }

  getPeople = () => {
    let { details, searchText } = this.props;

    if (searchText !== '') {
      searchText = searchText.replace(/["'()-]/g, '').replace(/ /g, '');
      const re = new RegExp(searchText, 'gi');

      details = details.filter(
        d =>
          d.FirstName.match(re) ||
          d.LastName.match(re) ||
          d.CellNumber.match(re) ||
          d.MembershipNum.toString().match(re)
      );
    }

    let people = details.filter(d => d.BBRoleCode === 'BB' || d.RoleCode === 'M');
    // people = [...people, ...details.filter(d => d.RoleCode === 'M')];
    // people = [...people, ...details.filter(d => d.RoleCode === 'LC')];

    const newTotalPages = Math.ceil(people.length / itemsPerPage);

    if (newTotalPages !== totalPages) {
      this.setState({ currentPage: 1 });
    }

    totalPages = newTotalPages;

    const page = people.slice(
      (this.state.currentPage - 1) * itemsPerPage,
      this.state.currentPage * itemsPerPage
    );

    return page;
  };

  countPeopleInRole = roleCode => {
    const { details } = this.props;
    return details.filter(
      d =>
        (d.PersonStatusCode === 'A') &&
        d.RoleCode === roleCode
    ).length;
  };

  countPeopleInBBRole = roleCode => {
    const { details } = this.props;
    return details.filter(
      d =>
        (d.PersonStatusCode === 'A') &&
        d.BBRoleCode === roleCode
    ).length;
  };

  pageChange = async (activePage, isTop) => {
    this.setState({
      currentPage: activePage
    });
    if (!isTop) {
      window.scrollTo(0, 0);
    }
  };

  render() {
    const {
      loading,
      setFiles,
      editUser,
      changeStatus,
      scrollRef
    } = this.props;
    const { currentPage } = this.state;
    const countMothers = this.countPeopleInRole('M');
    const countBirthingBuddies = this.countPeopleInBBRole('BB');

    return (
      <Fragment>
        { !loading && (
          <Segment>
            <Label
              color='pink'
              basic
              style={ { marginRight: 10, marginBottom: isMobileView() ? 10 : 0 } }
            >
              { countMothers } Mother{ countMothers === 1 ? '' : 's' }
            </Label>
            <Label
              color='blue'
              basic
              style={ { marginRight: 10, marginBottom: isMobileView() ? 10 : 0 } }
            >
              { countBirthingBuddies } Birthing Buddy{ countBirthingBuddies === 1 ? '' : 's' }
            </Label>
            {/*<Label color='grey' basic>*/ }
            {/*  { countLCs } Learner Consultant{ countLCs === 1 ? '' : 's' }*/ }
            {/*</Label>*/ }
          </Segment>
        ) }
        { !loading && totalPages !== 1 && (
          <Segment attached>
            <div style={ { textAlign: 'center' } }>
              <Paging
                totalPages={ totalPages }
                currentPage={ currentPage }
                onPageChange={ this.pageChange }
                isTop
              />
            </div>
          </Segment>
        ) }
        <Segment attached style={ { minHeight: 400 } }>
          <Card.Group itemsPerRow={ 2 } stackable>
            { loading && <LoadingComponent/> }
            { !loading &&
            this.getPeople().map(person => {
              return (
                <UserGridPersonItem
                  key={ person.PersonID }
                  person={ person }
                  setFiles={ setFiles }
                  editUser={ editUser }
                  changeStatus={ changeStatus }
                  scrollRef={ scrollRef }
                />
              );
            }) }
          </Card.Group>
        </Segment>
        { !loading && totalPages !== 1 && (
          <Segment attached>
            <div style={ { textAlign: 'center' } }>
              <Paging
                totalPages={ totalPages }
                currentPage={ currentPage }
                onPageChange={ this.pageChange }
              />
            </div>
          </Segment>
        ) }
      </Fragment>
    );
  }
}

export default compose(
  connect(
    mapState,
    actions
  )
)(UserGridPeopleGroup);
