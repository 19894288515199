import React, { Component, Fragment } from 'react';
import '../../App.css';
import ModalManager from '../modals/ModalManager';
import { Route, Switch, withRouter } from 'react-router';
import { Redirect } from "react-router-dom"
import HomePage from '../../features/home/homePage/HomePage';
import NavBar from '../../features/nav/NavBar/NavBar';
import { Container } from 'semantic-ui-react';
import { isAdminUser, isLoggedIn, isSuperAdminUser, isViewOnlyUser } from '../../features/auth/authActions';
import NotFound from './NotFound';
import FunctionModals from '../functionModals/FunctionModals';
// import ResetPasswordPage from '../../features/home/resetPasswordPage/ResetPasswordPage';
import { Helmet } from 'react-helmet';
import BYYAdminLanding from "../../features/byyAdmin/byyAdminLanding/BYYAdminLanding";
import RedirectPage from "../../app/common/utils/RedirectPage";
import ViewOnlyUserLanding from "../../features/viewOnlyUser/viewOnlyUserLanding/ViewOnlyUserLanding";


class App extends Component {
  componentDidMount() {
    let isIE = !!window.MSInputMethodContext && !!document.documentMode;
    if (!isIE) {
      let ua = window.navigator.userAgent;
      isIE = ua.indexOf('MSIE ') > 0;
    }
    if (isIE) {
      alert(
        'This browser is not supported. \r\nPlease use Chrome, Microsoft Edge or Firefox.'
      );
    }
  }

  render() {
    return (
      <Fragment>
        {/* Uncomment for analytics
        {process.env.NODE_ENV === 'production' &&
          <div>Analytics</div>} */}
        <ModalManager />
        <Route exact path='/' component={HomePage} />
        <Route
          path='/(.+)'
          render={() => (
            <Fragment>
              {isLoggedIn() && <NavBar />}
              <Container className='main'>
                <Helmet>
                  <title>BYY Admin</title>
                  <meta name='description' content='Admin'/>
                </Helmet>
                <Switch key={ this.props.location.key }>
                  <Route
                    path='/sua'
                    render={ () =>
                      isSuperAdminUser() ? <BYYAdminLanding/> : <Redirect to='/'/>
                    }
                  />
                  <Route
                    path='/au'
                    render={ () =>
                      isAdminUser() ? <BYYAdminLanding/> : <Redirect to='/'/>
                    }
                  />
                  <Route
                    path='/vo'
                    render={ () =>
                      isViewOnlyUser() ? <ViewOnlyUserLanding/> : <Redirect to='/'/>
                    }
                  />
                  <Route path='/redirect/:token' component={ RedirectPage }/>
                  <Route component={ NotFound }/>
                </Switch>
                { isLoggedIn() }
              </Container>
            </Fragment>
          )}
        />
        <FunctionModals />
      </Fragment>
    );
  }
}

export default withRouter(App);
