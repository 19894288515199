import React from 'react';
import { Form, Label } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parseISO } from 'date-fns';
import { isMobileView } from '../utils/common';

const DateInput = ({
                     input: { value, onChange, onBlur },
                     width,
                     labelWidth,
                     label,
                     disabled,
                     padding,
                     meta: { touched, error, active },
                     ...rest
                   }) => {
  const labelWidthpx = labelWidth ? `${ labelWidth }px` : '120px';
  const widthpx = width ? `${ width }px` : '100%';

  const divStyle = {
    padding: padding ? padding : '10px 10px 0 10px',
    position: 'relative'
  };

  return (
    <div style={ divStyle }>
      <Form.Field>
        <div
          className={ `ui labeled input ${ active &&
          ' element-focus' } ${ touched && error && ' element-error' }` }
          style={ { width: '100%' } }
        >
          { labelWidth !== '0' && (
            <div className='ui label label' style={ { width: labelWidthpx } }>
              { label }
            </div>
          ) }
          <DatePicker
            { ...rest }
            selected={
              value
                ? Object.prototype.toString.call(value) !== '[object Date]'
                ? parseISO(value)
                : value
                : null
            }
            style={ { maxWidth: widthpx, minWidth: widthpx } }
            showYearDropdown={ true }
            showMonthDropdown={ true }
            dropdownMode='select'
            todayButton='today'
            onChange={ onChange }
            onBlur={ (e, val) => onBlur(val) }
            onChangeRaw={ e => e.preventDefault() }
            disabled={ disabled }
            withPortal={ isMobileView() ? true : false }
          />
        </div>
        { touched && error && (
          <Label basic color='red' pointing='above'>
            { error }
          </Label>
        ) }
      </Form.Field>
    </div>
  );
};

export default DateInput;
