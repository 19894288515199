import React, { Fragment } from 'react';
import { Grid } from 'semantic-ui-react';
import { Field } from 'redux-form';
import TextInput from '../../../app/common/form/TextInput';
import { isAdminUser, isViewOnlyUser } from '../../auth/authActions';
import PhoneNumberInput from '../../../app/common/form/PhoneNumberInput';
import { combineValidators, isRequired } from 'revalidate';
import { isEmail } from '../../../app/common/utils/common';


export const validate = combineValidators({
  FirstNames: isRequired({ message: 'First Names is required' }),
  Surname: isRequired({ message: 'Surname is required' }),
  CellNumber: isRequired({ message: 'Cell Number is required' }),
  EmailAddress: isEmail({ message: 'Not a valid email address' }),
});

const UserEditDetailsFields = ({ forList, initialValues }) => {
  // const [roleCode, changeRoleCode] = useState(initialValues
  //   ? initialValues.RoleCode
  //   : null);

  // const educationList = () => {
  //   return educations.map(education => {
  //     return {
  //       key: education.EducationCode,
  //       text: education.EducationDesc,
  //       value: education.EducationCode
  //     };
  //   });
  // };
  //
  // const teamLeaderList = () => {
  //   return tutors
  //     .filter(t => t.RoleCode === 'TL')
  //     .map(tutor => {
  //       return {
  //         key: tutor.PersonID,
  //         text: tutor.Names,
  //         value: tutor.PersonID
  //       };
  //     });
  // };
  //
  // const syncPersonList = () => {
  //   return tutors.map(tutor => {
  //     return {
  //       key: tutor.PersonID,
  //       text: tutor.Names,
  //       value: tutor.PersonID
  //     };
  //   });
  // };
  //
  // const scheduleTypeList = () => {
  //   return getScheduleTypes().map(scheduleType => {
  //     return {
  //       key: scheduleType.ScheduleTypeCode,
  //       text: scheduleType.ScheduleTypeDesc,
  //       value: scheduleType.ScheduleTypeCode
  //     };
  //   });
  // };
  //
  // const shiftTypeList = () => {
  //   return getShiftTypes().map(shiftType => {
  //     return {
  //       key: shiftType.ShiftTypeCode,
  //       text: shiftType.ShiftTypeDesc,
  //       value: shiftType.ShiftTypeCode
  //     };
  //   });
  // };
  //
  // const groupList = () => {
  //   return groups.map(group => {
  //     return {
  //       key: group.PersonGroupID,
  //       text: group.PersonGroupDesc,
  //       value: group.PersonGroupID
  //     };
  //   });
  // };  

  // const editable = !isBYYAdmin() && !isTeamLeader() && !isAdminSupport();
  const editable = isAdminUser() || isViewOnlyUser();

  return (
    <Fragment>
      <Grid stackable>
        <Grid.Column width={ 8 }>
          <Field
            name='FirstName'
            component={ TextInput }
            label='Name'
            setFocus={ editable }
            disabled={ !editable }
          />
          <Field
            name='LastName'
            component={ TextInput }
            label='Surname'
            disabled={ !editable }
          />
        </Grid.Column>
        <Grid.Column width={ 8 }>
          <Field
            name='MobileNum'
            component={ PhoneNumberInput }
            label='Cell number'
            disabled={ !editable || !forList }
          />
          <Field
            name='EmailAddress'
            component={ TextInput }
            label='Email address'
            disabled={ !editable }
          />
        </Grid.Column>
      </Grid>
      <Grid stackable>
        <Grid.Column width={ 8 }>

        </Grid.Column>
        <Grid.Column width={ 8 }>

        </Grid.Column>
      </Grid>
    </Fragment>
  );
};

export default UserEditDetailsFields;
