import React, { Fragment } from "react";
import { Grid } from "semantic-ui-react";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import PieChart from "../charts/PieChart";


const ActiveMemberSchemeOptionDetails = ({ loading, activeMembersScheme }) => {
  const MemberCount = [];
  const labels = [];

  if (!loading && activeMembersScheme !== undefined) {
    activeMembersScheme.forEach(x => {
      MemberCount.push(x.ActiveMemberCount);
      labels.push(x.SchemeOptionDesc);
    });
  }

  return (
    <Fragment>
      { loading && <LoadingComponent/> }
      { !loading && (
        <Grid>
          <b style={ { fontSize: "36px" } }>
            {/* {activeMembersScheme.ActiveMemberCount} */ }
          </b>

          <Grid.Column width={ 16 }>
            { MemberCount.length === 0 && (
              <div
                style={ {
                  color: "silver",
                  textAlign: "center"
                } }
              >
                No stats available
              </div>
            ) }
            { MemberCount.length !== 0 && (
              <PieChart data={ MemberCount } labels={ labels }/>
            ) }
          </Grid.Column>
        </Grid>
      ) }
    </Fragment>
  );
};

export default ActiveMemberSchemeOptionDetails;
