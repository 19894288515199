import { combineReducers } from "redux";
import { reducer as FormReducer } from 'redux-form';
import { reducer as ToastrReducer } from 'react-redux-toastr';
import modalReducer from "../modals/modalReducer";
import authReducer from "../../features/auth/authReducer";
import authUserReducer from "../../features/authUser/authUserReducer";
import lookupReducer from "../common/lookups/lookupReducer";
import { USER_LOGOUT } from "./rootConstants";
import functionModalsReducer from "../functionModals/functionModalsReducer";
import userGridPeopleReducer from "../../features/userGrid/data/userGridPeople/userGridPeopleReducer";
import userNotificationsReducer from "../../features/user/data/userNotifications/userNotificationsReducer";
import byyAdminActionsReducer from "../../features/byyAdmin/byyAdminActions/data/byyAdminActionsReducer";
import statsReducer from "../../features/stats/data/statsReducer";

const appReducer = combineReducers({
  form: FormReducer,
  modals: modalReducer,
  toastr: ToastrReducer,
  functionModals: functionModalsReducer,
  auth: authReducer,
  authUser: authUserReducer,
  lookups: lookupReducer,
  people: userGridPeopleReducer,
  userNotifications: userNotificationsReducer,
  action: byyAdminActionsReducer,
  loadStats: statsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = undefined
  }

  return appReducer(state, action);
};

export default rootReducer
