import React from 'react';
import { Button, Divider, Segment, } from 'semantic-ui-react';

const HomePageButtons = ({ showLogin, showMoreInfo }) => {
  return (
    <div>
      {/* <span>label {process.env.REACT_APP_API_URL}</span> */ }
      <Segment className='segment-transparent'>
        <Divider horizontal>
          <Button.Group widths={ 8 }>
            <Button
              onClick={ showLogin }
              icon='sign in'
              fluid
              size='large'
              color='red'
              content='Login'
            />
            <Button.Or/>
            <Button
              onClick={ showMoreInfo }
              icon='info'
              fluid
              size='large'
              content='More Info'
            />
          </Button.Group>
        </Divider>
      </Segment>
    </div>
  );
};

export default HomePageButtons;
