import React, { Fragment } from 'react';
import UserEditDetails from '../../user/userEditDetails/UserEditDetails';
import { Helmet } from 'react-helmet';


const BYYAdminDetails = () => {
  return (
    <Fragment>
      <Helmet>
        <title>BYY Admin - My Details</title>
      </Helmet>
      <UserEditDetails/>
    </Fragment>
  );
};

export default BYYAdminDetails;
