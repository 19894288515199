import { MODAL_OPEN, MODAL_CLOSE, MODAL_CLOSEALL } from './modalConstants';

export const openModal = (modalType, modalProps) => {
  return {
    type: MODAL_OPEN,
    payload: {
      modalType,
      modalProps
    }
  };
};

export const closeModal = modalType => {
  return {
    type: MODAL_CLOSE,
    payload: {
      modalType
    }
  };
};

export const closeAllModals = () => {
  return {
    type: MODAL_CLOSEALL
  };
};
