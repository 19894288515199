import React, { Component } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { closeModal, openModal } from '../../../app/modals/modalActions';


const actions = { closeModal, openModal };

class HomePageMoreInfoModal extends Component {
  state = {
    isOpen: true
  };

  handleClose = () => {
    this.setState({
      isOpen: false
    });
    window.setTimeout(() => {
      this.props.closeModal('HomePageMoreInfoModal');
    }, this.props.animateFadeOutTime);
  };

  render() {
    const { isOpen } = this.state;
    return (
      <Modal
        size='tiny'
        open={ true }
        onClose={ this.handleClose }
        closeOnDimmerClick={ false }
        closeIcon
        className={ `modal-animation-open ${
          isOpen ? '' : 'modal-animation-closing'
        }` }
      >
        <Modal.Header>More Information</Modal.Header>
        <Modal.Content>
          Please contact BabyYumYum to add you to the admin system.<br/>
          Once you have access, then you will log in using your cell phone number.<br/>
          {/*<br/>*/ }
          {/*The first time your log in, a OTP will be sent to your cell phone. <br/>*/ }
          {/*Once verified, you will then be promted to create a password,<br />which you will then use from then onwards.*/ }
        </Modal.Content>
        <Modal.Actions>
          { ' ' }
          <Button
            onClick={ () => this.handleClose() }
            size='large'
            color='red'
            basic
            icon='close'
            content='Close'
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default connect(
  null,
  actions
)(HomePageMoreInfoModal);
