import {
  LOAD_ACTIVE_MOMS_MONTH,
  LOAD_BENEFITS_SUMMARY,
  LOAD_BENEFITS_SUMMARY_LABEL,
  LOAD_CURRENT_ACTIVE_MEMBERS_SCHEME_OPTIONS,
  LOAD_IMPORT_EXCEPTION_STATS,
  LOAD_MEAL_VOUCHER_STATS,
  LOAD_MOTHERS_PREGNANCY_PROFILE,
  STATS_LOADING,
  STATS_LOADING_COMPLETED
} from './statsConstants';
import { httpGet, httpPost } from '../../../app/common/utils/http';
import { toastr } from 'react-redux-toastr';


export const loadImportExceptions = (
  onlyComplete,
  setLoading = true
) => async (dispatch, getState) => {
  let values = {
    FromDate: "",
    ToDate: ""
  };

  if (setLoading) {
    await dispatch({ type: STATS_LOADING });
  }
  try {
    const response = await httpPost(
      'stats/GetAllImportExceptions', values
    );
    await dispatch({
      type: LOAD_IMPORT_EXCEPTION_STATS,
      payload: {
        ...response
      }
    });
  } catch (error) {
    toastr.error('Error', 'There was a problem getting import exceptions');
    throw error;
  } finally {
    await dispatch({ type: STATS_LOADING_COMPLETED });
  }
};

export const loadMealVoucherStats = (
  onlyComplete,
  setLoading = true
) => async (dispatch, getState) => {

  if (setLoading) {
    await dispatch({ type: STATS_LOADING });
  }
  try {
    const response = await httpGet(
      'stats/GetAllMealVoucherStats'
    );
    await dispatch({
      type: LOAD_MEAL_VOUCHER_STATS,
      payload: {
        ...response
      }
    });
  } catch (error) {
    toastr.error('Error', 'There was a problem getting meal voucher stats');
    throw error;
  } finally {
    await dispatch({ type: STATS_LOADING_COMPLETED });
  }
};

export const loadActiveMembersSchemeOption = (
  onlyComplete,
  setLoading = true
) => async (dispatch, getState) => {

  if (setLoading) {
    await dispatch({ type: STATS_LOADING });
  }
  try {
    const response = await httpGet(
      'stats/GetAllMemberPerSchemeOptionStats'
    );
    await dispatch({
      type: LOAD_CURRENT_ACTIVE_MEMBERS_SCHEME_OPTIONS,
      payload: {
        ...response
      }
    });
  } catch (error) {
    toastr.error('Error', 'There was a problem getting members scheme stats');
    throw error;
  } finally {
    await dispatch({ type: STATS_LOADING_COMPLETED });
  }
};

export const loadActiveMomsMonth = (
  onlyComplete,
  setLoading = true
) => async (dispatch, getState) => {

  if (setLoading) {
    await dispatch({ type: STATS_LOADING });
  }
  try {
    const response = await httpGet(
      'stats/GetAllMothersLoadedPerMonthStats'
    );
    await dispatch({
      type: LOAD_ACTIVE_MOMS_MONTH,
      payload: {
        ...response
      }
    });
  } catch (error) {
    toastr.error('Error', 'There was a problem getting the member growth stats');
    throw error;
  } finally {
    await dispatch({ type: STATS_LOADING_COMPLETED });
  }
};

export const loadBenefitsSummary = (
  onlyComplete,
  setLoading = true
) => async (dispatch, getState) => {

  if (setLoading) {
    await dispatch({ type: STATS_LOADING });
  }
  try {
    const response = await httpGet(
      'stats/GetAllMemberBenefitsEligibilityStats'
    );
    await dispatch({
      type: LOAD_BENEFITS_SUMMARY,
      payload: {
        ...response
      }
    });
    await dispatch({
      type: LOAD_BENEFITS_SUMMARY_LABEL,
      payload: {
        ...response
      }
    });
  } catch (error) {
    toastr.error('Error', 'There was a problem getting the benefits eligibility');
    throw error;
  } finally {
    await dispatch({ type: STATS_LOADING_COMPLETED });
  }
};

export const loadMothersPregnancyProfile = (
  onlyComplete,
  setLoading = true
) => async (dispatch, getState) => {

  if (setLoading) {
    await dispatch({ type: STATS_LOADING });
  }
  try {
    const response = await httpGet(
      'stats/GetAllMotherStagesStats'
    );
    await dispatch({
      type: LOAD_MOTHERS_PREGNANCY_PROFILE,
      payload: {
        ...response
      }
    });
  } catch (error) {
    toastr.error('Error', 'There was a problem getting the Pregnancy Profile');
    throw error;
  } finally {
    await dispatch({ type: STATS_LOADING_COMPLETED });
  }
};



