import React, { Component, Fragment } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { closeModal } from '../../app/modals/modalActions';
import { Button, Form, Modal } from 'semantic-ui-react';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { isMobileView } from '../../app/common/utils/common';

import { loadUserGridPerson, savePersonDetails } from '../userGrid/data/userGridPeople/userGridPeopleActions';
import MotherEditDetailsFields, { validate } from '../user/motherEditDetails/MotherEditDetailsFields';
import { isAdminUser } from "../auth/authActions";
import { loadPersonChildDeliveryTypeList } from "../../app/common/lookups/lookupActions";
import ReactTooltip from "react-tooltip";
import { loadUserGridPeople } from "./data/userGridPeople/userGridPeopleActions";


const mapState = state => ({
  loading: state.people.loadingItem,
  submitting: state.people.submitting,
  initialValues: state.people.person,
  personChildDeliveryTypes: state.lookups.personChildDeliveryTypes
});

const actions = {
  closeModal,
  loadUserGridPerson,
  loadPersonChildDeliveryTypeList,
  savePersonDetails,
  loadUserGridPeople
};

class UserGridPersonEditModal extends Component {
  state = {
    isOpen: true
  };

  async componentDidMount() {
    this.props.loadPersonChildDeliveryTypeList();

    if (this.props.personID !== undefined) {
      await this.props.loadUserGridPerson(this.props.personID);
    }
  }


  loadPersonDetails = async () => {
    await this.props.loadUserGridPerson(this.props.personID);
  };

  loadUserGridPeople = async (onlyActive, setLoading) => {
    await this.props.loadUserGridPeople(
      onlyActive,
      setLoading
    );
    ReactTooltip.rebuild();
  };


  isLoading = () => {
    const { loading } = this.props;
    return !!loading;
  };

  handleClose = () => {
    this.setState({
      isOpen: false
    });
    window.setTimeout(() => {
      this.props.closeModal('UserGridPersonEditModal');
    }, this.props.animateFadeOutTime);
  };

  handleSaveDetails = async values => {
    try {
      await this.props.savePersonDetails(values);
      this.handleClose();
    } catch (error) {
      // An error occured - the toast was thrown in the action
    }
  };

  render() {
    const { isOpen } = this.state;
    const {
      personID,
      handleSubmit,
      submitting,
      invalid,
      pristine,
      initialValues,
      personChildDeliveryTypes
    } = this.props;

    return (
      <Modal
        size='large'
        open={ true }
        onClose={ this.handleClose }
        closeOnDimmerClick={ false }
        closeIcon
        className={ `modal-animation-open ${
          isOpen ? '' : 'modal-animation-closing'
        }` }
      >
        <Modal.Header>
          { (this.isLoading() || !initialValues) && <Fragment>Person</Fragment> }
          { !this.isLoading() && initialValues && (
            <Fragment>
              { personID !== undefined
                ? `${ initialValues.FirstName } ${ initialValues.LastName }`
                : 'New Person' }
            </Fragment>
          ) }
        </Modal.Header>
        <Form
          autoComplete='off'
          onSubmit={ handleSubmit(this.handleSaveDetails) }
        >
          <Modal.Content scrolling={ isMobileView() } style={ { minHeight: 500 } }>
            { this.isLoading() && <LoadingComponent/> }
            { !this.isLoading() && (
              <MotherEditDetailsFields
                forList={ true }
                initialValues={ initialValues }
                personChildDeliveryTypeList={ personChildDeliveryTypes }
              />
            ) }
          </Modal.Content>
          <Modal.Actions>
            <Button.Group>
              { isAdminUser() &&
              <Button
                disabled={ invalid || submitting || pristine }
                loading={ submitting }
                size='large'
                color='red'
                icon='save'
                content='Save'
                style={ { width: 120 } }
              />
              }
              <Button
                disabled={ submitting }
                as='a'
                onClick={ this.handleClose }
                size='large'
                icon='close'
                content='Cancel'
                style={ { width: 120 } }
              />
            </Button.Group>
          </Modal.Actions>
        </Form>
      </Modal>
    );
  }
}

export default compose(
  connect(
    mapState,
    actions
  ),
  reduxForm({
    form: 'userGridPersonEditForm',
    validate,
    enableReinitialize: true
  })
)(UserGridPersonEditModal);
