import {
  LOOKUP_ACTION_STATUS_CODE_LIST,
  LOOKUP_PERSON_CHILD_DELIVERY_TYPE_LIST
} from './lookupConstants';
import { toastr } from 'react-redux-toastr';
import { httpGet } from '../utils/http';


export const loadActionStatusCodeList = () => async (dispatch, getState) => {
  if (getState().lookups.actionStatusCodes.length === 0) {
    try {
      const actionStatusCodes = await httpGet(`lookup/ActionStatusCodeGetAll`);
      await dispatch({ type: LOOKUP_ACTION_STATUS_CODE_LIST, payload: { actionStatusCodes } });
    } catch (error) {
      toastr.error('Error', 'There was a problem getting the action status codes');
      throw error;
    }
  }
};

export const loadPersonChildDeliveryTypeList = () => async (dispatch, getState) => {
  if (getState().lookups.personChildDeliveryTypes.length === 0) {
    try {
      const personChildDeliveryTypes = await httpGet(`lookup/PersonChildDeliveryTypeGetAll`);
      await dispatch({ type: LOOKUP_PERSON_CHILD_DELIVERY_TYPE_LIST, payload: { personChildDeliveryTypes } });
    } catch (error) {
      toastr.error('Error', 'There was a problem getting the delivery types');
      throw error;
    }
  }
};


export const getYesNoList = () => {
  return [
    { Code: '0', CodeDesc: 'No' },
    { Code: '1', CodeDesc: 'Yes' }
  ];
};
