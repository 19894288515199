import React from 'react';
import { Button, Icon, Input } from 'semantic-ui-react';
import { isMobileView } from '../../../app/common/utils/common';


const DashboardActionsSearchSortAndRefresh = ({
                                                headerActionsRef,
                                                refresh,
                                                refreshText,
                                                search,
                                                sort,
                                                sortValue,
                                                placeholderText
                                              }) => {
  return (
    <div ref={ headerActionsRef } className='header-actions'>
      <div style={ { float: 'left', marginTop: '-6px', marginRight: '-10px' } }>
        <Input
          icon={ isMobileView() ? null : 'search' }
          iconPosition={ isMobileView() ? null : 'left' }
          placeholder={ placeholderText }
          style={ { width: isMobileView() ? 70 : 200 } }
          onChange={ e => search(e.target.value) }
        />
      </div>
      <div style={ { float: 'right', marginTop: '-6px', marginRight: '-10px' } }>
        {/*<Popup*/ }
        {/*  position='bottom right'*/ }
        {/*  hoverable*/ }
        {/*  style={ { backgroundColor: 'lightcyan' } }*/ }
        {/*  trigger={*/ }
        {/*    <Button basic inverted circular icon>*/ }
        {/*      <Icon name='sort amount down'/>*/ }
        {/*    </Button>*/ }
        {/*  }*/ }
        {/*>*/ }
        {/*  <Popup.Header>Order By</Popup.Header>*/ }
        {/*  <Popup.Content>*/ }
        {/*    <Divider/>*/ }
        {/*    <div style={ { padding: 4 } }>*/ }
        {/*      <Radio*/ }
        {/*        toggle*/ }
        {/*        label='Actions'*/ }
        {/*        name='OrderBy'*/ }
        {/*        value='A'*/ }
        {/*        checked={ sortValue === 'A' }*/ }
        {/*        // onChange={ () => sort('A') }*/ }
        {/*      />*/ }
        {/*    </div>*/ }
        {/*    <div style={ { padding: 4 } }>*/ }
        {/*      <Radio*/ }
        {/*        toggle*/ }
        {/*        label='Not Complete'*/ }
        {/*        name='OrderBy'*/ }
        {/*        value='NC'*/ }
        {/*        checked={ sortValue === 'NC' }*/ }
        {/*        // onChange={ () => sort('NC') }*/ }
        {/*      />*/ }
        {/*    </div>*/ }
        {/*    <div style={ { padding: 4 } }>*/ }
        {/*      <Radio*/ }
        {/*        toggle*/ }
        {/*        label='Complaints Count'*/ }
        {/*        name='OrderBy'*/ }
        {/*        value='CA'*/ }
        {/*        checked={ sortValue === 'CA' }*/ }
        {/*        onChange={ () => sort('CA') }*/ }
        {/*      />*/ }
        {/*    </div>*/ }
        {/*  </Popup.Content>*/ }
        {/*</Popup>*/ }
        <Button
          basic
          inverted
          circular
          icon
          data-tip={ refreshText }
          onClick={ refresh }
        >
          <Icon name='refresh'/>
        </Button>
      </div>
    </div>
  );
};

export default DashboardActionsSearchSortAndRefresh;
