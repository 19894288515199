import React from 'react';
import { Button, Icon } from 'semantic-ui-react';


const DashboardActionsRefresh = ({
                                   headerActionsRef,
                                   refresh,
                                   refreshText
                                 }) => {
  return (
    <Button
      ref={ headerActionsRef }
      basic
      inverted
      circular
      floated='right'
      icon
      //className={`header-actions ${mouseOver ? 'header-actions-focus' : ''}`}
      className='header-actions'
      data-tip={ refreshText }
      onClick={ refresh }
    >
      <Icon name='refresh'/>
    </Button>
  );
};

export default DashboardActionsRefresh;
