import {
  ACTION_LOADING,
  ACTION_LOADING_COMPLETED,
  ACTION_LOADINGITEM,
  ACTION_LOADINGITEM_COMPLETED,
  ACTION_SUBMITTING,
  ACTION_SUBMITTING_COMPLETED,
  LOAD_ACTION,
  LOAD_ACTION_DETAILS
} from './byyAdminActionsConstants';
import { httpGet, httpPost } from '../../../../app/common/utils/http';
import { toastr } from 'react-redux-toastr';
import { convertDate } from "../../../../app/common/utils/common";


export const loadActions = (
  onlyComplete,
  setLoading = true
) => async (dispatch, getState) => {
  if (setLoading) {
    await dispatch({ type: ACTION_LOADING });
  }
  try {
    const response = await httpGet(
      `Admin/GetAllActions`
    );
    await dispatch({
      type: LOAD_ACTION,
      payload: {
        ...response
      }
    });
  } catch (error) {
    toastr.error('Error', 'There was a problem getting all actions');
    throw error;
  } finally {
    await dispatch({ type: ACTION_LOADING_COMPLETED });
  }
};

export const loadActionDetails = actionID => async (
  dispatch,
  getState
) => {
  await dispatch({ type: ACTION_LOADINGITEM });
  try {
    const response = await httpGet(`Admin/GetAction?ActionID=${ actionID }`);
    await dispatch({
      type: LOAD_ACTION_DETAILS,
      payload: {
        ...response
      }
    });
  } catch (error) {
    toastr.error('Error', 'There was a problem getting action details');
    throw error;
  } finally {
    await dispatch({ type: ACTION_LOADINGITEM_COMPLETED });
  }
};

export const saveActionEdit = userAction => async (
  dispatch,
  getState
) => {
  await dispatch({
    type: ACTION_SUBMITTING,
  });

  try {
    // let response = await httpPost(`Admin/SaveAction`, {
    //   PersonID: userAction.PersonID,
    //   Details: userAction
    // });

    userAction.BagDeliveredOnDate = convertDate(userAction.BagDeliveredOnDate);

    let response = await httpPost(`Admin/SaveAction`, userAction);
    toastr.success('Success', 'The action details were saved');

    await dispatch({
      type: LOAD_ACTION_DETAILS,
      payload: {
        ...response
      }
    });
  } catch (error) {
    toastr.error('Error', error);
    throw error;
  } finally {
    await dispatch({ type: ACTION_SUBMITTING_COMPLETED });
  }
};
