import React, { Fragment } from 'react';
import { Pagination } from 'semantic-ui-react';
import { isMobileView } from './common';

const Paging = ({ totalPages, currentPage, onPageChange, isTop }) => {
  const pageChange = async (event, data) => {
    onPageChange(data.activePage, isTop === null || isTop === undefined ? false : isTop);
  };
  return (
    <Fragment>
      Page
      {isMobileView() && (
        <Fragment>
          <br />
          <Pagination
            totalPages={totalPages}
            firstItem={null}
            lastItem={null}
            prevItem={null}
            nextItem={null}
            activePage={currentPage}
            onPageChange={pageChange}
            ellipsisItem={false}
          />
        </Fragment>
      )}
      {!isMobileView() && (
        <Fragment>
          <Pagination
            totalPages={totalPages}
            firstItem={null}
            lastItem={null}
            activePage={currentPage}
            onPageChange={pageChange}
            style={{ marginLeft: 20 }}
          />
        </Fragment>
      )}      
    </Fragment>
  );
};

export default Paging;
