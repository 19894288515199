import React from 'react';
import { Header, Icon, Menu } from 'semantic-ui-react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { isMobileView } from '../../../app/common/utils/common';
import { toggleMenuOpen } from '../../user/data/user/userActions';


const mapState = state => ({
  menuOpen: state.menuOpen
  // menuOpen: state.user.menuOpen
});

const actions = {
  toggleMenuOpen
};

const BYYAdminNav = ({ menuOpen, toggleMenuOpen }) => {
  const isMobile = isMobileView();

  return (
    <Menu vertical fluid>
      { isMobile && (
        <Icon
          onClick={ () => toggleMenuOpen() }
          name={ `arrow alternate circle ${ menuOpen ? 'up' : 'down' }` }
          style={ { float: 'right', paddingTop: 8 } }
          inverted
          size='big'
        />
      ) }
      {/*<Header*/ }
      {/*  onClick={ () => toggleMenuOpen() }*/ }
      {/*  icon='user'*/ }
      {/*  attached={ 'top' }*/ }
      {/*  inverted*/ }
      {/*  color='grey'*/ }
      {/*  content='Navigation'*/ }
      {/*/>*/ }
      <Header
        onClick={ () => toggleMenuOpen() }
        icon='user'
        attached
        inverted
        color='grey'
        content='Navigation'
      />
      <div
        style={ {
          height: !isMobile || menuOpen ? '100%' : '0',
          overflow: 'hidden'
        } }
      >
        <Menu.Item as={ NavLink } to='/au/dashboard'>
          <Icon name='dashboard'/> Dashboard
        </Menu.Item>
        <Menu.Item as={ NavLink } to='/au/people'>
          <Icon name='users'/> People
        </Menu.Item>
        <Menu.Item as={ NavLink } to='/au/benefits'>
          <Icon name='trophy'/> Benefit Eligibility
        </Menu.Item>
        <Menu.Item as={ NavLink } to='/au/action'>
          <Icon name='ordered list'/> Action
        </Menu.Item>
        <Menu.Item as={ NavLink } to='/au/importex'>
          <Icon name='file excel outline'/> Import Exceptions
        </Menu.Item>
        <Menu.Item as={ NavLink } to='/au/userdetails'>
          <Icon name='user'/> My Details
        </Menu.Item>
      </div>
    </Menu>
  );
};

export default compose(
  connect(
    mapState,
    actions
  )
)(BYYAdminNav);
