import React from "react";
import { Bar } from "react-chartjs-2";

const BarChart = ({
                    data,
                    labels,
                    bgcolours,
                    headings,
                    legends,
                    height = 270,
                    xlabel = ''
                  }) => {
  let heading = JSON.stringify(headings);
  return (
    <Bar
      height={ height }
      data={ {
        datasets: [
          {
            data: data,
            backgroundColor: bgcolours[0],
            borderColor: bgcolours[0],
            borderWidth: 0.5,
            label: legends
          }

        ],

        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: labels
      } }
      options={ {
        responsive: true,
        legend: { display: false },
        maintainAspectRatio: true,
        tooltips: {
          enabled: true
        },

        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: xlabel
              }
            },
          ],
          yAxes: [
            {
              display: true,
              ticks: { fontSize: 10 },
              scaleLabel: {
                display: true,
                labelString: headings
              },
              stacked: true
            }
          ]
        },
        gridLines: {
          display: false,
          offsetGridLines: true
        },
        title: { display: false, text: "", fontSize: 22 }
      } }
    />
  );
};

export default BarChart;
