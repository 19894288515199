import React, { Component, createRef } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { confirm, inputBox } from '../../../app/functionModals/functionModalsActions';
import { openModal } from '../../../app/modals/modalActions';
import ReactTooltip from 'react-tooltip';
import DashboardActionsSearchSortAndRefresh from "../../common/dashboardActions/DashboardActionsSearchSortAndRefresh";
import { loadImportExceptions } from "../data/statsActions";
import ImportExceptionTableGroup from "./ImportExceptionTableGroup";
import LoadingComponent from "../../../app/layout/LoadingComponent";


const mapState = state => ({
  initialized: state.initialized,
  loading: state.loading,
  importException: state.loadStats.importExceptions
});

const actions = {
  loadImportExceptions,
  openModal,
  confirm,
  inputBox
};


class ImportException extends Component {
  state = {
    searchText: '',
    sortValue: 'A',
    currentPage: 1,
    importException: null,
    column: null,
    direction: null,
  };

  headerActionsRef = createRef();
  scrollRef = createRef();

  loadImportExceptions = async (setLoading) => {
    await this.props.loadImportExceptions(
      setLoading
    );
    ReactTooltip.rebuild();
  };

  refreshImportExceptions = async () => {
    await this.props.loadImportExceptions(true);
  };

  showHeaderActions = () => {
    this.headerActionsRef.current.classList.add('header-actions-focus');
  };

  hideHeaderActions = () => {
    if (this.state.searchText === '') {
      this.headerActionsRef.current.classList.remove('header-actions-focus');
    }
  };

  searchImportExceptions = searchText => {
    this.setState({
      searchText
    });
  };

  editAction = action => {
    this.props.openModal('BYYActionEditModal', {
      ActionID: action.ActionID
      // updatedPerson: this.updatedPerson
    });
  };

  sort = sortValue => {
    this.setState({
      sortValue
    });
  };

  render() {

    const {
      initialized = true,
      loading
    } = this.props;

    const {
      searchText,
    } = this.state;

    return (
      <Segment.Group
        onMouseMove={ () => this.showHeaderActions() }
        onMouseLeave={ () => this.hideHeaderActions() }
      >
        <Segment inverted color='red' style={ { height: '50px' } }>
          <Header floated='left'>File Import Exception</Header>

          <DashboardActionsSearchSortAndRefresh
            headerActionsRef={ this.headerActionsRef }
            refreshText='Refresh File Import Errors'
            refresh={ this.refreshImportExceptions }
            search={ this.searchImportExceptions }
            placeholderText='Search File Error...'
          />

        </Segment>
        <Segment attached>
          { (!initialized || loading) && <LoadingComponent/> }
          { initialized && (
            <ImportExceptionTableGroup
              searchText={ searchText }
              scrollRef={ this.scrollRef }
            />
          ) }
        </Segment>
      </Segment.Group>
    );
  }
}

export default compose(
  connect(
    mapState,
    actions
  )
)(ImportException);
