import React from 'react';
import { Doughnut } from 'react-chartjs-2';


const getGraphColour = i => {

  let colours = [
    'rgba(211, 15, 96, 0.5)',
    'rgba(136, 115, 0, 0.5)',
    'rgba(211, 82, 239, 0.5)',
    'rgba(66, 220, 201, 0.5)',
    'rgba(57, 199, 0, 0.5)',
    'rgba(155, 214, 251, 0.5)',
    'rgba(7, 8, 122, 0.5)',
    'rgba(119, 34, 247, 0.5)',
    'rgba(7, 8, 122, 0.5)',
    'rgba(6, 97, 206, 0.5)',
    'rgba(90, 12, 25, 0.5)',
    'rgba(178, 84, 58, 0.5)',
    'rgba(169, 144, 148, 0.5)',
    'rgba(6, 101, 160, 0.5)',
    'rgba(240, 79, 98, 0.5)',
    'rgba(225, 221, 167, 0.5)',
    'rgba(8, 227, 106, 0.5)',
    'rgba(134, 198, 8, 0.5)',
    'rgba(249, 152, 157, 0.5)',
    'rgba(44, 134, 63, 0.5)',
    'rgba(12, 237, 234, 0.5)',
    'rgba(152, 243, 206, 0.5)',
    'rgba(211, 198, 14, 0.5)',
    'rgba(222, 75, 249, 0.5)',
    'rgba(97,242,0,0.8)',
    'rgba(57,230,195,0.8)',
    'rgba(0,43,64,0.8)',
    'rgba(177,163,217,0.8)',
    'rgba(115,29,86,0.8)',


  ]
  for (let j = 1; j < colours.length + 1; j++) {
    if (i === j) {
      return colours[j]
    }
  }


};

const getGraphBorderColour = i => {
  let colours = [
    'rgba(211, 15, 96, 0.5)',
    'rgba(136, 115, 0, 0.5)',
    'rgba(211, 82, 239, 0.5)',
    'rgba(66, 220, 201, 0.5)',
    'rgba(57, 199, 0, 0.5)',
    'rgba(155, 214, 251, 0.5)',
    'rgba(7, 8, 122, 0.5)',
    'rgba(119, 34, 247, 0.5)',
    'rgba(7, 8, 122, 0.5)',
    'rgba(6, 97, 206, 0.5)',
    'rgba(90, 12, 25, 0.5)',
    'rgba(178, 84, 58, 0.5)',
    'rgba(169, 144, 148, 0.5)',
    'rgba(6, 101, 160, 0.5)',
    'rgba(240, 79, 98, 0.5)',
    'rgba(225, 221, 167, 0.5)',
    'rgba(8, 227, 106, 0.5)',
    'rgba(134, 198, 8, 0.5)',
    'rgba(249, 152, 157, 0.5)',
    'rgba(44, 134, 63, 0.5)',
    'rgba(12, 237, 234, 0.5)',
    'rgba(152, 243, 206, 0.5)',
    'rgba(211, 198, 14, 0.5)',
    'rgba(222, 75, 249, 0.5)',
    'rgba(97,242,0,0.8)',
    'rgba(57,230,195,0.8)',
    'rgba(0,43,64,0.8)',
    'rgba(177,163,217,0.8)',
    'rgba(115,29,86,0.8)',


  ]
  for (let j = 1; j < colours.length + 1; j++) {
    if (i === j) {
      return colours[j]
    }
  }
};

const PieChart = ({ data, labels }) => {
  var bgcolours = [];
  var bordercolours = [];
  let i = 0;
  data.forEach(d => {
    i++;
    if (i > 10) {
      i = 1;
    }
    bgcolours.push(getGraphColour(i));
    bordercolours.push(getGraphBorderColour(i));
  });

  return (
    <Doughnut
      data={ {
        datasets: [
          {
            data: data,
            backgroundColor: bgcolours,
            borderColor: bordercolours,
            borderWidth: 1
          }
        ],

        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: labels
      } }
      options={ {
        responsive: true,
        legend: { display: true, position: 'left', align: 'start' },
        maintainAspectRatio: true,
        scales: {
          xAxes: [{ display: false, scaleLabel: { display: false } }],
          yAxes: [
            {
              display: false,
              scaleLabel: { display: false, labelString: '' }
            }
          ]
        },
        layout: { padding: { top: 0, right: 2 } },
        title: { display: false, text: '', fontSize: 22 }
      } }
    />
  );
};

export default PieChart;
