import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { closeAllModals } from '../../../app/modals/modalActions';
import { reset, validateLogin } from '../../auth/authActions';
// import LoginFormValidateCell from './LoginFormValidateCell';
// import LoginFormValidateOTP from './LoginFormValidateOTP';
// import LoginFormNewPassword from './LoginFormNewPassword';
// import LoginFormValidatePassword from './LoginFormValidatePassword';
import LoginForm from './LoginForm';
import { compose } from 'redux';
import { withRouter } from 'react-router';


const mapState = state => ({
  loading: state.auth.loading,
  // loadingName: state.auth.loadingName,
  loggedIn: state.auth.loggedIn
});

const actions = {
  closeAllModals,
  reset,
  validateLogin
};

class LoginModal extends Component {
  state = {
    isOpen: true
  };

  async componentWillUnmount() {
    this.props.reset();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.loggedIn && this.props.loggedIn) {
      this.props.hasLoggedIn();
      this.handleClose();
    }
  }

  handleClose = () => {
    this.setState({
      isOpen: false
    });
    window.setTimeout(() => {
      this.props.closeAllModals();
    }, this.props.animateFadeOutTime);
  };

  handleValidateLogin = async values => {
    values["cellNumber"] = values["cellNumber"].replace(/\s/g, '').replace(/[{()}]/g, '').replace(/-/g, '');
    await this.props.validateLogin({ ...values });
  };

  handleValidateCell = async values => {
    await this.props.validateCell({ ...values, forLoginYN: true });
  };

  handleResendOTP = async () => {
    await this.props.resendOTP(true);
  };

  handleValidateOTP = async values => {
    await this.props.validateOTP({
      cellNumber: this.props.cellNumber,
      ...values
    });
  };

  handleCreateNewPassword = async values => {
    await this.props.createPassword({
      cellNumber: this.props.cellNumber,
      otp: this.props.otpNumber,
      ...values
    });
  };

  validatePassword = async values => {
    await this.props.validatePassword({
      cellNumber: this.props.cellNumber,
      ...values
    });
  };

  forgottenPassword = () => {
    this.props.history.push(`/resetpassword`);
    this.handleClose();
  };

  render() {
    const { isOpen } = this.state;
    const {
      loading,
      // loadingName,
    } = this.props;

    return (
      <Modal
        size='mini'
        open={ true }
        onClose={ this.handleClose }
        closeOnDimmerClick={ false }
        closeIcon
        className={ `modal-animation-open ${
          isOpen ? '' : 'modal-animation-closing'
        }` }
      >
        <Modal.Header>Login</Modal.Header>
        <LoginForm
          validateLogin={ this.handleValidateLogin }
          loading={ loading }
        />

        {/*{!cellValidated && (*/ }
        {/*  <LoginFormValidateCell*/ }
        {/*    validateCell={this.handleValidateCell}*/ }
        {/*    loading={loading}*/ }
        {/*    forgottenPassword={this.forgottenPassword}*/ }
        {/*  />*/ }
        {/*)}*/ }

        {/*{cellValidated && !cellActivated && !otpValidated && (*/ }
        {/*  <LoginFormValidateOTP*/ }
        {/*    validateOTP={this.handleValidateOTP}*/ }
        {/*    loading={loading}*/ }
        {/*    loadingName={loadingName}*/ }
        {/*    cellNumber={cellNumber}*/ }
        {/*    resendOTP={this.handleResendOTP}*/ }
        {/*  />*/ }
        {/*)}*/ }

        {/*{otpValidated && (*/ }
        {/*  <LoginFormNewPassword*/ }
        {/*    createNewPassword={this.handleCreateNewPassword}*/ }
        {/*    loading={loading}*/ }
        {/*  />*/ }
        {/*)}*/ }

        {/*{cellValidated && cellActivated && (*/ }
        {/*  <LoginFormValidatePassword*/ }
        {/*    validatePassword={this.validatePassword}*/ }
        {/*    loading={loading}*/ }
        {/*    cellNumber={cellNumber}*/ }
        {/*    forgottenPassword={this.forgottenPassword}*/ }
        {/*  />*/ }
        {/*)}*/ }
      </Modal>
    );
  }
}

export default compose(
  withRouter,
  connect(
    mapState,
    actions
  )
)(LoginModal);
