import { matchesPattern } from 'revalidate';

export const isMobileView = () => {
  return window.innerWidth <= 768;
};

export const convertDate = date => {
  let returndate = date;
  if (Object.prototype.toString.call(date) === '[object Date]') {
    // We have to take the SA time zone date - and manipulate it to a GMT time zone - else when it reaches the API the time will differ by 2 hours
    let datestring = date.toString();
    const index = datestring.indexOf('GMT');
    datestring = datestring.substring(0, index + 3);
    returndate = new Date(Date.parse(datestring)).toISOString();
  }
  return returndate;
};

export const downloadFile = url => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', url);

  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const delay = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const formatCell = cell => {
  if (cell.length === 10) {
    return `(${cell.substring(0, 3)}) ${cell.substring(
      3,
      6
    )} - ${cell.substring(6, 10)}`;
  } else {
    return cell;
  }
};

// **************************************************
// Validators
// **************************************************
export const isEmail = matchesPattern(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/);
export const isRating = matchesPattern(/^(10|\d)(\.\d{1,8})?$/);
