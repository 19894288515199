import React, { Component, createRef } from "react";
import { Header, Segment } from "semantic-ui-react";
import { compose } from "redux";
import { connect } from "react-redux";
import { loadMealVoucherStats } from "../data/statsActions";
import DashboardActionsRefresh from "../../common/dashboardActions/DashboardActionsRefresh";
import MealVoucherDetail from "./MealVoucherDetails";
import LoadingComponent from "../../../app/layout/LoadingComponent";

const mapState = (state) => ({
	initialized: state.initialized,
	loading: state.loading,
	importException: state.loadStats.importExceptions,
	mealVoucherStats: state.loadStats.mealVoucherStats,
});

const actions = {
	loadMealVoucherStats,
};

class MealVoucher extends Component {
	headerActionsRef = createRef();

	async componentDidMount() {
		if (!this.props.loaded) {
			await this.loadMealVoucherStats();
		}
	}

	loadMealVoucherStats = async (setLoading) => {
		await this.props.loadMealVoucherStats(setLoading);
	};

	refreshMealVoucherStats = async () => {
		await this.props.loadMealVoucherStats(true);
	};

	showHeaderActions() {
		this.headerActionsRef.current.ref.current.classList.add(
			"header-actions-focus"
		);
	}

	hideHeaderActions() {
		this.headerActionsRef.current.ref.current.classList.remove(
			"header-actions-focus"
		);
	}

	render() {
		const { loading, mealVoucherStats } = this.props;

		return (
			<Segment.Group
				onMouseMove={() => this.showHeaderActions()}
				onMouseLeave={() => this.hideHeaderActions()}
			>
				<Segment inverted color='red' style={{ height: "50px" }}>
					<Header floated='left'>Meal Voucher</Header>
					{!loading && (
						<DashboardActionsRefresh
							headerActionsRef={this.headerActionsRef}
							refreshText='Refresh Meal Voucher'
							refresh={this.refreshMealVoucherStats}
						/>
					)}
					{loading && <LoadingComponent />}
				</Segment>
				<Segment attached>
					{!loading && (
						<MealVoucherDetail
							loading={loading}
							mealVoucherDetail={mealVoucherStats}
						/>
					)}
					{loading && <LoadingComponent />}
				</Segment>
			</Segment.Group>
		);
	}
}

export default compose(connect(mapState, actions))(MealVoucher);
