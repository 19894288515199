import { createReducer } from '../../../../app/store/createReducer';
import {
  CLEAR_USERNOTIFICATIONS,
  LOAD_USERNOTIFICATIONS,
  NOTIFICATION_DISMISS,
  NOTIFICATION_REMOVE,
  USERNOTIFICATIONS_INITIALIZED,
  USERNOTIFICATIONS_LOADING,
  USERNOTIFICATIONS_LOADING_COMPLETED,
  USERNOTIFICATIONS_LOADINGITEM,
  USERNOTIFICATIONS_LOADINGITEM_COMPLETED,
  USERNOTIFICATIONS_SUBMITTING,
  USERNOTIFICATIONS_SUBMITTING_COMPLETED
} from './userNotificationsConstants';


function getInitialState() {
  return {
    initialized: false,
    loading: false,
    loadingItem: false,
    submitting: false,
    submittingKey: null,
    notifications: [],
    dismissed: [],
    requestCancelSlots: [],
    leaveRequests: [],
    requestSlotsDeclined: []
  };
}

const initialState = getInitialState();

const initialized = (state, payload) => {
  return {
    ...state,
    initialized: true
  };
};

const loading = (state, payload) => {
  return {
    ...state,
    loading: true
  };
};

const loadingCompleted = (state, payload) => {
  return {
    ...state,
    loading: false
  };
};

const loadingItem = (state, payload) => {
  return {
    ...state,
    loadingItem: true
  };
};

const loadingItemCompleted = (state, payload) => {
  return {
    ...state,
    loadingItem: false
  };
};

const submitting = (state, payload) => {
  return {
    ...state,
    submitting: true,
    submittingKey: payload.submittingKey
  };
};

const submittingCompleted = (state, payload) => {
  return {
    ...state,
    submitting: false,
    submittingKey: null
  };
};

const loadNotifications = (state, payload) => {
  return {
    ...state,
    notifications: payload.Notifications
  };
};

const clearNotifications = (state, payload) => {
  return getInitialState();
};

const dismissNotification = (state, payload) => {
  return {
    ...state,
    dismissed: [...state.dismissed, payload.notificationKey]
  };
};

const removeNotification = (state, payload) => {
  return {
    ...state,
    notifications: [
      ...state.notifications.filter(
        n => n.NotificationID !== payload.NotificationID
      )
    ]
  };
};

export default createReducer(initialState, {
  [USERNOTIFICATIONS_INITIALIZED]: initialized,
  [USERNOTIFICATIONS_LOADING]: loading,
  [USERNOTIFICATIONS_LOADING_COMPLETED]: loadingCompleted,
  [USERNOTIFICATIONS_LOADINGITEM]: loadingItem,
  [USERNOTIFICATIONS_LOADINGITEM_COMPLETED]: loadingItemCompleted,
  [USERNOTIFICATIONS_SUBMITTING]: submitting,
  [USERNOTIFICATIONS_SUBMITTING_COMPLETED]: submittingCompleted,
  [LOAD_USERNOTIFICATIONS]: loadNotifications,
  [CLEAR_USERNOTIFICATIONS]: clearNotifications,
  [NOTIFICATION_DISMISS]: dismissNotification,
  [NOTIFICATION_REMOVE]: removeNotification,
});
