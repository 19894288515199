import React, { useState } from 'react';
import { Divider, Header } from 'semantic-ui-react';
import PersonChangeStatusField from './PersonChangeStatusField';
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";


const PersonChangeStatus = ({ initialValues: { PersonStatusCode } }) => {
  const [personStatusCode, changeStatus] = useState(PersonStatusCode);

  return (
    <div style={ { padding: 20 } }>
      <Header>Status</Header>
      <Divider/>
      <Grid columns={ 2 }>
        <Grid.Row>
          <Grid.Column>
            <PersonChangeStatusField
              statusCode='A'
              label='Active'
              colour='green'
              personStatusCode={ personStatusCode }
              changeStatus={ changeStatus }
            />
          </Grid.Column>
          <Grid.Column>
            <PersonChangeStatusField
              statusCode='I'
              label='Inactive'
              colour='red'
              personStatusCode={ personStatusCode }
              changeStatus={ changeStatus }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default PersonChangeStatus;
