import React, { Component, createRef } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { loadMothersPregnancyProfile } from "../data/statsActions";
import DashboardActionsRefresh from '../../common/dashboardActions/DashboardActionsRefresh';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import MothersPregnancyProfileDetails from './MothersPregnancyProfileDetails';


const mapState = state => ({
  initialized: state.initialized,
  loading: state.loading,
  mothersPregnancyProfile: state.loadStats.mothersPregnancyProfile
});

const actions = {
  loadMothersPregnancyProfile
};


class MothersPregnancyProfile extends Component {
  headerActionsRef = createRef();

  async componentDidMount() {
    if (!this.props.loaded) {
      await this.loadMothersPregnancyProfile();
    }
  }

  loadMothersPregnancyProfile = async (setLoading) => {
    await this.props.loadMothersPregnancyProfile(
      setLoading
    );

  };

  refreshActions = async () => {
    await this.props.loadMothersPregnancyProfile(true);
  };

  showHeaderActions() {
    this.headerActionsRef.current.ref.current.classList.add(
      'header-actions-focus'
    );
  }

  hideHeaderActions() {
    this.headerActionsRef.current.ref.current.classList.remove(
      'header-actions-focus'
    );
  }


  render() {
    const { loading, mothersPregnancyProfile } = this.props;


    return (
      <Segment.Group
        onMouseMove={ () => this.showHeaderActions() }
        onMouseLeave={ () => this.hideHeaderActions() }
      >
        <Segment inverted color='red' style={ { height: '50px' } }>
          <Header floated='left'>Mother Stages</Header>
          { !loading &&
          <DashboardActionsRefresh
            headerActionsRef={ this.headerActionsRef }
            refreshText='Refresh Pregnancy Profile'
            refresh={ this.loadMothersPregnancyProfile }
          />
          }
          { loading &&
          <LoadingComponent/>
          }
        </Segment>
        <Segment attached>
          { !loading &&
          <MothersPregnancyProfileDetails
            loading={ loading }
            pregnancyProfile={ mothersPregnancyProfile }
          />
          }
          { loading &&
          <LoadingComponent/>
          }
        </Segment>
      </Segment.Group>
    );
  }
}

export default compose(
  connect(
    mapState,
    actions
  )
)(MothersPregnancyProfile);
