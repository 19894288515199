import { createReducer } from '../../../app/store/createReducer';
import {
  LOAD_ACTIVE_MOMS_MONTH,
  LOAD_BENEFITS_SUMMARY,
  LOAD_BENEFITS_SUMMARY_LABEL,
  LOAD_CURRENT_ACTIVE_MEMBERS_SCHEME_OPTIONS,
  LOAD_IMPORT_EXCEPTION_STATS,
  LOAD_MEAL_VOUCHER_STATS,
  LOAD_MOTHERS_PREGNANCY_PROFILE,
  STATS_LOADING,
  STATS_LOADING_COMPLETED
} from './statsConstants';


const initialState = {
  loading: false,
  loadingItem: false,
  submitting: false,
  importExceptions: [],
  importException: null,
  mealVoucherStats: [],
  currentActiveMembersSchemeOption: null,
  activeMomsMonth: null,
  benefitsSummary: [],
  benefitsSummaryLabel: []
};

const loading = (state, payload) => {
  return {
    ...state,
    loading: true
  };
};

const loadingCompleted = (state, payload) => {
  return {
    ...state,
    loading: false
  };
};

const loadingItem = (state, payload) => {
  return {
    ...state,
    loadingItem: true
  };
};

const loadingItemCompleted = (state, payload) => {
  return {
    ...state,
    loadingItem: false
  };
};

const submitting = (state, payload) => {
  return {
    ...state,
    submitting: true
  };
};

const submittingCompleted = (state, payload) => {
  return {
    ...state,
    submitting: false,
    submittingKey: null
  };
};


const loadImportExceptions = (state, payload) => {
  return {
    ...state,
    importExceptions: payload.Details
  };
};

const loadImportExceptionDetails = (state, payload) => {
  return {
    ...state,
    importException: payload
  };
};

const loadMealVoucherStats = (state, payload) => {
  return {
    ...state,
    mealVoucherStats: payload.Details
  }
};

const loadActiveMembersSchemeOption = (state, payload) => {
  return {
    ...state,
    activeMembersSchemeOption: payload.Details
  }
};

const loadActiveMomsMonth = (state, payload) => {
  return {
    ...state,
    activeMomsMonth: payload.Details
  }
};

const loadBenefitsSummary = (state, payload) => {

  return {
    ...state,
    benefitsSummary: payload.Details
    //may be called difrent later on
  }
};

const loadBenefitsSummaryLabel = (state, payload) => {
  return {
    ...state,
    benefitsSummaryLabel: payload.Summary
  }
}

const loadMothersPregnancyProfile = (state, payload) => {
  return {
    ...state,
    mothersPregnancyProfile: payload.Details
  }
}

export default createReducer(initialState, {
  [STATS_LOADING]: loading,
  [STATS_LOADING_COMPLETED]: loadingCompleted,
  [LOAD_IMPORT_EXCEPTION_STATS]: loadImportExceptions,
  [LOAD_MEAL_VOUCHER_STATS]: loadMealVoucherStats,
  [LOAD_CURRENT_ACTIVE_MEMBERS_SCHEME_OPTIONS]: loadActiveMembersSchemeOption,
  [LOAD_ACTIVE_MOMS_MONTH]: loadActiveMomsMonth,
  [LOAD_BENEFITS_SUMMARY]: loadBenefitsSummary,
  [LOAD_MOTHERS_PREGNANCY_PROFILE]: loadMothersPregnancyProfile,
  [LOAD_BENEFITS_SUMMARY_LABEL]: loadBenefitsSummaryLabel
});
