import { createReducer } from "../../store/createReducer";
import {
  LOOKUP_ACTION_STATUS_CODE_LIST,
  LOOKUP_PERSON_CHILD_DELIVERY_TYPE_LIST
} from "./lookupConstants";


const initialState = {
  actionStatusCodes: [],
  personChildDeliveryTypes: []
};


const getActionStatusCodeList = (state, payload) => {
  return {
    ...state,
    actionStatusCodes: payload.actionStatusCodes.ActionStatusCodeList
  }
};

const getPersonChildDeliveryTypeGetAll = (state, payload) => {
  return {
    ...state,
    personChildDeliveryTypes: payload.personChildDeliveryTypes.PersonChildDeliveryTypeList
  }
};

export default createReducer(initialState, {
  [LOOKUP_ACTION_STATUS_CODE_LIST]: getActionStatusCodeList,
  [LOOKUP_PERSON_CHILD_DELIVERY_TYPE_LIST]: getPersonChildDeliveryTypeGetAll
})