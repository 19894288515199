import { toastr } from 'react-redux-toastr';
import { AUTH_LOADING, AUTH_LOADING_COMPLETED, AUTH_RESET, LOAD_VERSION, LOGIN } from './authConstants';
import { hasValidToken, httpGet, httpPost } from '../../app/common/utils/http';
import jwt_decode from 'jwt-decode';
import { USER_LOGOUT } from '../../app/store/rootConstants';
// import { registerPushServiceWorker } from './pushNotifications';

export const reset = loginModel => async (dispatch, getState) => {
  await dispatch({ type: AUTH_RESET });
};

// New Login for Admin
export const validateLogin = loginModel => async (dispatch, getState) => {
  await dispatch({ type: AUTH_LOADING });
  try {
    // Change URL for POST
    const user = await httpPost(`auth/AdminLogin`, loginModel);
    // Set local storage
    login(user);
    await dispatch({ type: LOGIN });

  } catch (error) {
    toastr.error('Error', error);
  } finally {
    await dispatch({ type: AUTH_LOADING_COMPLETED });
  }
};

const login = user => {
  localStorage.setItem('user', user.Token);
  localStorage.setItem('displayName', user.DisplayName);
  toastr.success('Success', 'Welcome ' + user.DisplayName);

  //registerPushServiceWorker();
};

export const isLoggedIn = () => {
  if (!hasValidToken()) {
    return false;
  }
  let decodedToken = getDecodedToken();
  return decodedToken.person && decodedToken.person !== '';
};

export const isSuperAdminUser = () => {
  return checkRole('Super Admin User');
};

export const isAdminUser = () => {
  return checkRole('Admin User');
};

export const isStandardUser = () => {
  return checkRole('Standard');
};

export const isApproverUser = () => {
  return checkRole('Approver');
};

export const isViewOnlyUser = () => {
  return checkRole('View Only');
};


function checkRole(role) {
  if (!isLoggedIn()) {
    return false;
  }
  let decodedToken = getDecodedToken();
  return decodedToken.role && decodedToken.role === role;
}

export const getRole = () => {
  if (!isLoggedIn) {
    return false;
  }
  let decodedToken = getDecodedToken();
  return decodedToken.role;
};

export const setRoleUrl = () => {
  let dbRole = getRole();
  let urlRole = '';
  if (!isLoggedIn) {
    return false;
  }

  switch (dbRole) {
    case 'Super Admin User':
      urlRole = 'SAU';
      break;
    case 'Admin User':
      urlRole = 'AU';
      break;
    case 'Standard':
      urlRole = 'S';
      break;
    case 'Approver':
      urlRole = 'A';
      break;
    case 'View Only':
      urlRole = 'VO';
      break;
    default:
      urlRole = 'VO';
  }

  return urlRole;
};


function getDecodedToken() {
  let token = localStorage.getItem('user');
  let decodedToken = jwt_decode(token);
  return decodedToken;
}

export const logout = () => async (dispatch, getState) => {
  await unsubscribePushNotifications();
  logoutLocalStorage();
  window.setTimeout(() => {
    dispatch({ type: USER_LOGOUT }); // If we logout straight away - we haven't returned to the home screen yet, and some components rerender and try get information from the API
  }, 500);
};

export function logoutLocalStorage() {
  localStorage.removeItem('user');
  localStorage.removeItem('displayName');
}

export const unsubscribePushNotifications = async () => {
  try {
    await httpPost('push/Unsubscribe', { userAgent: navigator.userAgent });
  } catch (error) {
    // Don't show an error - just continue
    // toastr.error('Error', "Couldn't unsubscribe from push notifications");
  }
};

export const loadVersion = () => async (dispatch, getState) => {
  try {
    const version = await httpGet(`home/GetReactVersion`);
    await dispatch({ type: LOAD_VERSION, payload: { version } });
  } catch (error) {
    toastr.error('Error', 'Could not get version');
  }
};