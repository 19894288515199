import { USER_MENU2_OPEN, USER_MENU_OPEN } from './userConstants';


export const toggleMenuOpen = () => async (dispatch, getState) => {
  await dispatch({ type: USER_MENU_OPEN });
};

export const toggleMenu2Open = () => async (dispatch, getState) => {
  await dispatch({ type: USER_MENU2_OPEN });
};
