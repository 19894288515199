import React, { Component, Fragment } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { closeModal } from '../../../app/modals/modalActions';
import { Button, Form, Modal } from 'semantic-ui-react';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { isMobileView } from '../../../app/common/utils/common';

import { loadActionStatusCodeList } from '../../../app/common/lookups/lookupActions';
import { loadActionDetails, loadActions, saveActionEdit } from './data/byyAdminActionsActions';
import ActionEditDetailsFields, { validate } from '../byyAdminActions/ActionEditDetailsFields';
import { isAdminUser } from "../../auth/authActions";


const mapState = state => ({
  loading: state.action.loadingItem,
  submitting: state.action.submitting,
  initialValues: state.action.action,
  actionStatusCodes: state.lookups.actionStatusCodes
});

const actions = {
  closeModal,
  loadActionDetails,
  loadActions,
  loadActionStatusCodeList,
  saveActionEdit
};

class BYYActionEditModal extends Component {
  state = {
    isOpen: true
  };

  async componentDidMount() {
    this.props.loadActionStatusCodeList();

    if (this.props.ActionID !== undefined) {
      await this.props.loadActionDetails(this.props.ActionID);
    }
  }

  isLoading = () => {
    const { loading } = this.props;
    return !!loading;
  };

  handleClose = () => {
    this.setState({
      isOpen: false
    });
    window.setTimeout(() => {
      this.props.closeModal('BYYActionEditModal');
    }, this.props.animateFadeOutTime);
  };

  handleSaveAction = async values => {
    try {
      await this.props.saveActionEdit(values);
      this.handleClose();
      await this.props.loadActions();

    } catch (error) {
      // An error occured - the toast was thrown in the action
    }
  };

  checkCompleteInd = () => {
    const { initialValues } = this.props;
    return initialValues.CompletedInd === 1;
  };

  render() {
    const { isOpen } = this.state;
    const {
      ActionID,
      handleSubmit,
      submitting,
      invalid,
      pristine,
      initialValues,
      actionStatusCodes
    } = this.props;

    return (
      <Modal
        size='tiny'
        open={ true }
        onClose={ this.handleClose }
        closeOnDimmerClick={ false }
        closeIcon
        className={ `modal-animation-open ${
          isOpen ? '' : 'modal-animation-closing'
        }` }
      >
        <Modal.Header>
          { (this.isLoading() || !initialValues) && <Fragment>Complete</Fragment> }
          { !this.isLoading() && initialValues && (
            <Fragment>
              { ActionID !== undefined
                ? `${ initialValues.MothersName } ${ initialValues.MothersSurname }`
                : 'Mark As Complete' }
            </Fragment>
          ) }
        </Modal.Header>
        <Form
          autoComplete='off'
          onSubmit={ handleSubmit(this.handleSaveAction) }
        >
          <Modal.Content scrolling={ isMobileView() } style={ { minHeight: 250 } }>
            { this.isLoading() && <LoadingComponent/> }
            { !this.isLoading() && (
              <ActionEditDetailsFields
                forList={ true }
                initialValues={ initialValues }
                actionStatusCodes={ actionStatusCodes }
              />
            ) }
          </Modal.Content>
          <Modal.Actions>
            <Button.Group>
              { isAdminUser() &&
              <Button
                disabled={ invalid || submitting || pristine || initialValues.CompletedInd === 1 }
                loading={ submitting }
                size='large'
                color='red'
                icon='save'
                content='Save'
                style={ { width: 120 } }
              />
              }
              < Button
                disabled={ submitting }
                as='a'
                onClick={ this.handleClose }
                size='large'
                icon='close'
                content='Cancel'
                style={ { width: 120 } }
              />

            </Button.Group>
          </Modal.Actions>
        </Form>
      </Modal>
    );
  }
}

export default compose(
  connect(
    mapState,
    actions
  ),
  reduxForm({
    form: 'BYYActionEditModal',
    validate,
    enableReinitialize: true
  })
)(BYYActionEditModal);
