import React from 'react';
import { connect } from 'react-redux';
import HomePageMoreInfoModal from '../../features/home/homePage/HomePageMoreInfoModal';
import LoginModal from '../../features/home/loginModal/LoginModal';
import UserGridPersonEditModal from '../../features/userGrid/UserGridPersonEditModal';
import PersonChangeStatusModal from '../../features/userGrid/PersonChangeStatusModal/PersonChangeStatusModal';
import BYYActionEditModal from '../../features/byyAdmin/byyAdminActions/BYYActionEditModal';
import cuid from 'cuid';


const modalLookup = {
  HomePageMoreInfoModal,
  LoginModal,
  UserGridPersonEditModal,
  PersonChangeStatusModal,
  BYYActionEditModal
};

const mapState = state => ({
  currentModals: state.modals
});

const ModalManager = ({ currentModals }) => {     
  let renderedModals = [];
  if (currentModals && currentModals.modals.length > 0) {
    currentModals.modals.forEach(model => {
      const ModalComponent = modalLookup[model.modalType];
      renderedModals.push(
        <ModalComponent
          {...model.modalProps}
          animateFadeInTime={currentModals.animateFadeInTime}
          animateFadeOutTime={currentModals.animateFadeOutTime}
        />
      );
    });
  }
  return renderedModals.map(renderedModal => (
    <span key={cuid()}>{renderedModal}</span>
  ));
};

export default connect(mapState)(ModalManager);
