import React from 'react';
import { Form } from 'semantic-ui-react';

const RadioInput = ({ input, width, type, label, toggle, id, setvalue, checked, disabled, labelStyle }) => {
  return (
    <Form.Field>
      <div className={`ui ${toggle ? 'checkbox toggle' : 'radio'}`}>      
        <input id={id} {...input} value={setvalue} type='radio' checked={checked} disabled={disabled} /> 
        <label htmlFor={id} style={{cursor: 'pointer'}}><span style={labelStyle}>{label}</span></label>
      </div>
    </Form.Field>
  );
};

export default RadioInput;
