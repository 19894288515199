import React from 'react'
import { Field } from 'redux-form';
import RadioInput from '../../../app/common/form/RadioInput';


const PersonChangeStatusField = ({ statusCode, label, colour, personStatusCode, changeStatus }) => {
  return (
    <Field
      onChange={ () => changeStatus(statusCode) }
      name='PersonStatusCode'
      id={ `PersonStatusCode_${ statusCode }` }
      component={ RadioInput }
      toggle={ true }
      label={ label }
      checked={ personStatusCode === statusCode }
      value={ statusCode }
      setvalue={ statusCode }
      labelStyle={ {
        fontWeight: personStatusCode === statusCode ? 'bold' : 'normal',
        color: personStatusCode === statusCode ? colour : 'black'
      } }
    />
  )
};

export default PersonChangeStatusField
