import React, { Fragment } from 'react';
import { Button, Card, Header, Icon, Label } from 'semantic-ui-react';
import { format } from 'date-fns'
import { isAdminUser } from "../auth/authActions";
import { formatCell } from "../../app/common/utils/common";


const personStatus_Active = 'A';
const personStatus_Inactive = 'I';

const UserGridPersonItem = ({
                              person,
                              setFiles,
                              editUser,
                              changeStatus,
                              scrollRef
                            }) => {

  function getColor() {
    // Role is type
    let switchRole;
    if (person.BBRoleCode) {
      switchRole = person.BBRoleCode
    } else {
      switchRole = person.RoleCode
    }
    switch (switchRole) {
      case 'M':
        return 'pink';
      case 'BB':
        return 'blue';
      // case 'MM':
      //   return '#ff66c4';
      // case 'B':
      //   return '#38b6ff';
      default:
        return null;
    }
  }

  function getStatusColor() {
    // Is the person active or inactive
    switch (person.PersonStatusCode) {
      case personStatus_Active:
        return 'green';
      case personStatus_Inactive:
        return 'red';
      default:
        return 'transparent';
    }
  }

  function getStatusDescription() {
    switch (person.PersonStatusCode) {
      case personStatus_Active:
        return 'Active';
      case personStatus_Inactive:
        return 'Inactive';
      default:
        return '';
    }
  }

  function getFormattedDate(date) {
    return format(new Date(date), 'dd/MM/yyyy');
  }

  return (
    <Card color={ getColor() } style={ { width: '46%' } }>
      <div ref={ scrollRef ? scrollRef : null }/>
      <Card.Content>
        { person.RoleCode === 'M' && (
          <Label
            color={ getColor() }
            ribbon='right'
            style={ {
              position: 'absolute',
              left: 'calc(100% + 1.2em)',
              top: 40,
              width: 130,
            } }
          >
            { person.PersonChildID !== -1 && (
              <Icon name='child'/>
            ) }
            Mother
          </Label>
        ) }
        { person.BBRoleCode === 'BB' && (
          <Label
            color={ getColor() }
            ribbon='right'
            style={ {
              position: 'absolute',
              left: 'calc(100% + 1.2em)',
              top: 40,
              width: 130
            } }
          >
            { person.PersonChildID !== -1 && (
              <Icon name='child'/>
            ) }
            Mother & Birthing Buddy
          </Label>
        ) }
        <Card.Header>
          { person.FirstName } { person.LastName }
        </Card.Header>
        <Card.Meta>
          <span style={ { color: getStatusColor() } }>
            { getStatusDescription() }
          </span>
        </Card.Meta>
      </Card.Content>
      { person.RoleCode === 'M' && (
        <Card.Content>
          { person.CellNumber && (
            <Fragment>
              <br/>
              <u>Cell Number:</u>&nbsp;&nbsp;
              { formatCell(person.CellNumber) }
            </Fragment>
          ) }
          { person.SchemeOption && (
            <Fragment>
              <br/>
              <u>Scheme Option:</u>&nbsp;&nbsp;
              { person.SchemeOption }
            </Fragment>
          ) }
          { person.ExpectedDueDate && (
            <Fragment>
              <br/>
              <u>EDD:</u>&nbsp;&nbsp;
              { getFormattedDate(person.ExpectedDueDate) }
            </Fragment>
          ) }
          { person.WeeksPregnantEDD && (
            <Fragment>
              <br/>
              <u>Weeks Pregnant:</u>&nbsp;&nbsp;
              { person.WeeksPregnantEDD }
            </Fragment>
          ) }
          { person.MembershipNum && (
            <Fragment>
              <br/>
              <u>Membership No:</u>&nbsp;&nbsp;
              { person.MembershipNum }
            </Fragment>
          ) }
          { person.MaternityAuthNum && (
            <Fragment>
              <br/>
              <u>Maternity Auth No:</u>&nbsp;&nbsp;
              { person.MaternityAuthNum }
            </Fragment>
          ) }
        </Card.Content>
      ) }
      { person.BBRoleCode === 'BB' && (
        <Card.Content extra>
          { person.BBFirstName && person.BBLastName && (
            <Fragment>
              <span><Header as='h4'
                            color='blue'>Birthing Buddy:</Header> { person.BBFirstName } { person.BBLastName } - { formatCell(person.BBCellNumber) }</span>
            </Fragment>
          ) }
        </Card.Content>
      ) }
      <Card.Content extra>
        <Button.Group fluid={ person.RoleCode !== 'CM' }>
          <Button
            onClick={ () => editUser(person) }
            color='grey'
            icon={ isAdminUser() ? 'pencil' : 'eye' }
            content={ isAdminUser() ? 'Edit' : 'View' }
            // style={ person.RoleCode === 'CM' ? { width: 110 } : {} }
          />
          { isAdminUser() && (
            <Button onClick={ () => changeStatus(person) } basic color='grey'>
              Status
            </Button>
          ) }
        </Button.Group>
      </Card.Content>
    </Card>
  );
};

export default UserGridPersonItem;
