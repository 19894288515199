import React from "react";
import { Table } from "semantic-ui-react";
import { format } from "date-fns";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";


const ActionStatusCode_Initialised = "Initialised";
const ActionStatusCode_In_Progress = "In Progress";
const ActionStatusCode_Attempted_Retry = "Attempted Retry";
const ActionStatusCode_Completed = "Completed";
const ActionStatusCode_Failed = "Failed";

let benefits = [];


const BenefitsSummaryItem = ({ key, benefitsSummary, scrollRef, bags }) => {

  function getFormattedDate(date) {
    return format(new Date(date), "dd/MM/yyyy");
  }

  return (
    <Table.Row key={ benefitsSummary.PersonID }>
      <Table.Cell>
        { benefitsSummary.FirstName } { benefitsSummary.LastName }
      </Table.Cell>
      <Table.Cell>{ benefitsSummary.MembershipNum }</Table.Cell>

      <Table.Cell textAlign='center'>
        { benefitsSummary.Benefits.map((answer, i) => {
          if (answer.BenefitID === 1 && answer.BenefitIssuedYN === 1) {
            return <Icon size='large' color='green' name='check circle'/>
          } else if (answer.BenefitID === 1 && answer.BenefitIssuedYN === 0) {
            return <Icon size='large' color='grey' name='check circle'/>
          }
        }) }
      </Table.Cell>
      <Table.Cell textAlign='center'>
        { benefitsSummary.Benefits.map((answer, i) => {
          if (answer.BenefitID === 2 && answer.BenefitIssuedYN === 1) {
            return <Icon size='large' color='green' name='check circle'/>
          } else if (answer.BenefitID === 2 && answer.BenefitIssuedYN === 0) {
            return <Icon size='large' color='grey' name='check circle'/>
          }
        }) }
      </Table.Cell>
      <Table.Cell textAlign='center'>
        { benefitsSummary.Benefits.map((answer, i) => {
          if (answer.BenefitID === 3 && answer.BenefitIssuedYN === 1) {
            return <Icon size='large' color='green' name='check circle'/>
          } else if (answer.BenefitID === 3 && answer.BenefitIssuedYN === 0) {
            return <Icon size='large' color='grey' name='check circle'/>
          }
        }) }
      </Table.Cell>
      <Table.Cell textAlign='center'>
        { benefitsSummary.Benefits.map((answer, i) => {
          if (answer.BenefitID === 4 && answer.BenefitIssuedYN === 1) {
            return <Icon size='large' color='green' name='check circle'/>
          } else if (answer.BenefitID === 4 && answer.BenefitIssuedYN === 0) {
            return <Icon size='large' color='grey' name='check circle'/>
          }
        }) }
      </Table.Cell>
      <Table.Cell textAlign='center'>
        { benefitsSummary.Benefits.map((answer, i) => {
          if (answer.BenefitID === 5 && answer.BenefitIssuedYN === 1) {
            return <Icon size='large' color='green' name='check circle'/>
          } else if (answer.BenefitID === 5 && answer.BenefitIssuedYN === 0) {
            return <Icon size='large' color='grey' name='check circle'/>
          }
        }) }
      </Table.Cell>

      {/*<Table.Cell>*/ }
      {/*  <Button.Group fluid>*/ }
      {/*    <Button*/ }
      {/*      compact*/ }
      {/*      onClick={ () => editAction(benefitsSummary) }*/ }
      {/*      basic color={ isAdminUser() ? 'blue' : 'grey' }*/ }
      {/*      content={ isAdminUser() ? 'Edit' : 'View' }*/ }
      {/*      icon={ isAdminUser() ? 'pencil' : 'eye' }*/ }
      {/*    >*/ }
      {/*    </Button>*/ }
      {/*  </Button.Group>*/ }
      {/*</Table.Cell>*/ }
    </Table.Row>
  );
};

export default BenefitsSummaryItem;
