import React, { Component, createRef, Fragment } from 'react';
import { Grid, Sticky } from 'semantic-ui-react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { isMobileView } from '../../../app/common/utils/common';
import { Helmet } from 'react-helmet';
import ReactTooltip from 'react-tooltip';
import BYYAdminActions from "../../byyAdmin/byyAdminActions/BYYAdminActions";
import BYYAdminPeople from "../../byyAdmin/byyAdminPeople/BYYAdminPeople";
import ViewOnlyUserNav from "./ViewOnlyUserNav";
import ViewOnlyUserDetails from "../viewOnlyUserDetails/ViewOnlyUserDetails";
import ViewOnlyUserDashboard from "../viewOnlyUserDashboard/ViewOnlyUserDashboard";
import ImportException from "../../stats/importException/ImportException";
import BenefitsSummary from '../../stats/benefitsSummary/BenefitsSummary';


class ViewOnlyUserLanding extends Component {
  contextRef = createRef();

  render() {
    const isMobile = isMobileView();
    return (
      <Fragment>
        <Helmet>
          <title>BYY Admin - View Only</title>
        </Helmet>
        <Grid stackable>
          <Grid.Column width={ 4 } style={ { minWidth: '250px' } }>
            <Sticky
              context={ this.contextRef }
              active={ !isMobile }
              offset={ 60 }
              styleElement={ { zIndex: 0 } }
            >
              {/* NAVBAR */ }
              <ViewOnlyUserNav/>
            </Sticky>
          </Grid.Column>
          <Grid.Column width={ 12 }>
            <div ref={ this.contextRef }>
              <Switch>
                <Redirect exact from='/vo' to='/vo/dashboard'/>
                <Route exact path='/vo/dashboard' component={ ViewOnlyUserDashboard }/>
                <Route exact path='/vo/people' component={ BYYAdminPeople }/>
                <Route exact path='/vo/benefits' component={ BenefitsSummary }/>
                <Route exact path='/vo/action' component={ BYYAdminActions }/>
                <Route exact path='/vo/importex' component={ ImportException }/>
                <Route exact path='/vo/userdetails' component={ ViewOnlyUserDetails }/>
                <Route render={ () => <Redirect to='/NotFound'/> }/>
              </Switch>
            </div>
          </Grid.Column>
        </Grid>
        <ReactTooltip effect='solid'/>
      </Fragment>
    );
  }
}

export default ViewOnlyUserLanding;
