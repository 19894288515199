import React, { Fragment } from 'react';
import { Grid } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import ActiveMemberSchemeOption from "../../stats/activeMembersSchemeOption/ActiveMemberSchemeOption";
import MealVoucher from "../../stats/mealVoucher/MealVoucher";
import MothersLoadedPerMonth from '../../stats/mothersLoadedPerMonth/MothersLoadedPerMonth';
import MothersPregnancyProfile from '../../stats/mothersPregnancyProfile/MothersPregnancyProfile';

const ViewOnlyUserDashboard = () => {
  return (
    <Fragment>
      <Helmet>
        <title>View Only - Dashboard</title>
      </Helmet>

      <Grid stackable>
        <Grid.Column width={ 16 }>
          <ActiveMemberSchemeOption/>
        </Grid.Column>
        <Grid.Column width={ 8 }>
          <MealVoucher/>
        </Grid.Column>
        <Grid.Column width={ 8 }>
          <MothersLoadedPerMonth/>
        </Grid.Column>
        < Grid.Column width={ 16 }>
          <MothersPregnancyProfile/>
        </Grid.Column>
      </Grid>
    </Fragment>
  );
};

export default ViewOnlyUserDashboard;
