import React, { Component, createRef } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { loadActiveMomsMonth } from "../data/statsActions";
import DashboardActionsRefresh from '../../common/dashboardActions/DashboardActionsRefresh';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import MothersLoadedPerMonthDetails from './MothersLoadedPerMonthDetails';


const mapState = state => ({
  initialized: state.initialized,
  loading: state.loading,
  activeMomsMonth: state.loadStats.activeMomsMonth
});

const actions = {
  loadActiveMomsMonth
};


class MothersLoadedPerMonth extends Component {
  headerActionsRef = createRef();

  async componentDidMount() {
    if (!this.props.loaded) {
      await this.loadActiveMomsMonth();
    }
  }

  loadActiveMomsMonth = async (setLoading) => {
    await this.props.loadActiveMomsMonth(
      setLoading
    );

  };

  refreshActions = async () => {
    await this.props.loadActiveMomsMonth(true);
  };

  showHeaderActions() {
    this.headerActionsRef.current.ref.current.classList.add(
      'header-actions-focus'
    );
  }

  hideHeaderActions() {
    this.headerActionsRef.current.ref.current.classList.remove(
      'header-actions-focus'
    );
  }


  render() {
    const { loading, activeMomsMonth } = this.props;


    return (
      <Segment.Group
        onMouseMove={ () => this.showHeaderActions() }
        onMouseLeave={ () => this.hideHeaderActions() }
      >
        <Segment inverted color='red' style={ { height: '50px' } }>
          <Header floated='left'>Member Growth</Header>
          { !loading &&
          <DashboardActionsRefresh
            headerActionsRef={ this.headerActionsRef }
            refreshText='Refresh Member Growth'
            refresh={ this.loadActiveMomsMonth }
          />
          }
          { loading &&
          <LoadingComponent/>
          }
        </Segment>
        <Segment attached>
          { !loading &&
          <MothersLoadedPerMonthDetails
            loading={ loading }
            activeMomsMonthDetails={ activeMomsMonth }
          />
          }
          { loading &&
          <LoadingComponent/>
          }
        </Segment>
      </Segment.Group>
    );
  }
}

export default compose(
  connect(
    mapState,
    actions
  )
)(MothersLoadedPerMonth);
