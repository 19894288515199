export const USERNOTIFICATIONS_INITIALIZED = 'USERNOTIFICATIONS_INITIALIZED';
export const USERNOTIFICATIONS_LOADING = 'USERNOTIFICATIONS_LOADING';
export const USERNOTIFICATIONS_LOADING_COMPLETED =
  'USERNOTIFICATIONS_LOADING_COMPLETED';
export const USERNOTIFICATIONS_LOADINGITEM = 'USERNOTIFICATIONS_LOADINGITEM';
export const USERNOTIFICATIONS_LOADINGITEM_COMPLETED =
  'USERNOTIFICATIONS_LOADINGITEM_COMPLETED';
export const USERNOTIFICATIONS_SUBMITTING = 'USERNOTIFICATIONS_SUBMITTING';
export const USERNOTIFICATIONS_SUBMITTING_COMPLETED =
  'USERNOTIFICATIONS_SUBMITTING_COMPLETED';
export const LOAD_USERNOTIFICATIONS = 'LOAD_USERNOTIFICATIONS';
export const CLEAR_USERNOTIFICATIONS = 'CLEAR_USERNOTIFICATIONS';
export const NOTIFICATION_DISMISS = 'NOTIFICATION_DISMISS';
export const NOTIFICATION_REMOVE = 'NOTIFICATION_REMOVE';