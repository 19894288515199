import { createReducer } from '../../app/store/createReducer';
import {
  AUTH_LOADING,
  AUTH_LOADING_COMPLETED,
  AUTH_RESET,
  CELL_VALIDATED,
  LOAD_VERSION,
  LOGIN,
  OTP_VALIDATED
} from './authConstants';

const getInitialState = () => {
  return {
    loading: false,
    loadingName: null,
    cellValidated: false,
    cellActivated: false,
    cellNumber: null,
    otpValidated: false,
    otpNumber: null,
    pushNotification: null,
    version: null
  };
};

const initialState = {
  ...getInitialState(),
  loggedIn: false
};

const loading = (state, payload) => {
  return {
    ...state,
    loading: true,
    loadingName: payload ? payload.loadingName : null
  };
};

const loadingCompleted = (state, payload) => {
  return {
    ...state,
    loading: false,
    loadingName: null
  };
};

const reset = (state, payload) => {
  return {
    ...getInitialState()
  };
};

const cellValidated = (state, payload) => {
  return {
    ...state,
    cellValidated: true,
    cellActivated: payload.activated,
    cellNumber: payload.cellNumber
  };
};

const otpValidated = (state, payload) => {
  return {
    ...state,
    otpValidated: true,
    otpNumber: payload.otpNumber
  };
};

const login = (state, payload) => {
  return {
    ...state,
    loggedIn: true
  };
};

const loadVersion = (state, payload) => {
  return {
    ...state,
    version: payload.version
  };
};

export default createReducer(initialState, {
  [AUTH_LOADING]: loading,
  [AUTH_LOADING_COMPLETED]: loadingCompleted,
  [AUTH_RESET]: reset,
  [CELL_VALIDATED]: cellValidated,
  [OTP_VALIDATED]: otpValidated,
  [LOGIN]: login,
  [LOAD_VERSION]: loadVersion
});
