import React from 'react';
import { Button, Table } from 'semantic-ui-react';
import { format } from 'date-fns'
import { isAdminUser } from "../../auth/authActions";
import { formatCell } from "../../../app/common/utils/common";


const ActionStatusCode_Initialised = 'Initialised';
const ActionStatusCode_In_Progress = 'In Progress';
const ActionStatusCode_Attempted_Retry = 'Attempted Retry';
const ActionStatusCode_Completed = 'Completed';
const ActionStatusCode_Failed = 'Failed';

const BYYAdminActionItem = ({
                              action,
                              setFiles,
                              editAction,
                              changeStatus,
                              scrollRef
                            }) => {

  function getColor() {
    // Role is type
    let ActionTypeCode;
    switch (ActionTypeCode) {
      case 'Call':
        return 'red';
      case 'DispatchBag':
        return 'blue';
      // case 'MM':
      //   return '#ff66c4';
      // case 'B':
      //   return '#38b6ff';
      default:
        return null;
    }
  }

  function getStatusColor() {
    switch (action.ActionStatusCode) {
      case ActionStatusCode_Initialised:
        return 'blue';
      case ActionStatusCode_In_Progress:
        return 'violet';
      case ActionStatusCode_Attempted_Retry:
        return 'orange';
      case ActionStatusCode_Completed:
        return 'green';
      case ActionStatusCode_Failed:
        return 'red';
      default:
        return 'transparent';
    }
  }

  function getFormattedDate(date) {
    return format(new Date(date), 'dd/MM/yyyy');
  }

  return (
    <Table.Row key={ action.ActionID }>
      <Table.Cell>
        { action.MothersName } { action.MothersSurname }
      </Table.Cell>
      <Table.Cell>
        { formatCell(action.MothersCellNum) }
      </Table.Cell>
      <Table.Cell>
        { action.ActionTypeCode }
      </Table.Cell>
      <Table.Cell>
         <span style={ { color: getStatusColor() } }>
            { action.ActionStatusCode }
          </span>
      </Table.Cell>
      <Table.Cell>
        { getFormattedDate(action.DateIssued) }
      </Table.Cell>
      <Table.Cell>
        { action.CompletedDate == null ? 'Not Completed' : getFormattedDate(action.CompletedDate) }
      </Table.Cell>
      <Table.Cell>

        <Button.Group fluid>
          <Button
            compact
            onClick={ () => editAction(action) }
            basic color={ isAdminUser() ? 'blue' : 'grey' }
            content={ isAdminUser() ? 'Edit' : 'View' }
            icon={ isAdminUser() ? 'pencil' : 'eye' }
          >
          </Button>
        </Button.Group>
      </Table.Cell>
    </Table.Row>
  );
};

export default BYYAdminActionItem;
