import React, { useState, useRef, useEffect, Fragment } from 'react';
import {
  confirmOK,
  inputBoxResult,
  closeFunctionModal
} from './functionModalsActions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import FunctionAlert from './FunctionAlert';
import FunctionConfirm from './FunctionConfirm';
import FunctionInputBox from './FunctionInputBox';

const mapState = state => ({
  showAlert: state.functionModals.showAlert,
  showConfirm: state.functionModals.showConfirm,
  showInputBox: state.functionModals.showInputBox,
  text: state.functionModals.text,
  initialValue: state.functionModals.initialValue,
  okButtonText: state.functionModals.okButtonText,
  cancelButtonText: state.functionModals.cancelButtonText
});

const actions = {
  confirmOK,
  inputBoxResult,
  closeFunctionModal
};

const FunctionModals = ({
  showAlert,
  showConfirm,
  showInputBox,
  text,
  initialValue,
  okButtonText,
  cancelButtonText,
  confirmOK,
  inputBoxResult,
  closeFunctionModal
}) => {
  function handleConfirmOK() {
    confirmOK();
    handleCloseFunctionModal();
  }

  function handleInputBoxResult() {
    inputBoxResult(result);
    handleCloseFunctionModal();
  }

  function handleCloseFunctionModal() {
    changeClosing(true);
    window.setTimeout(() => {
      changeClosing(false);
      changeResult('');
      closeFunctionModal();
    }, 250)
  }

  const [result, changeResult] = useState(initialValue);
  const [closing, changeClosing] = useState(false);

  const inputEl = useRef(null);

  useEffect(() => {
    if (showInputBox) {
      window.setTimeout(() => {
        inputEl.current.focus();
      }, 100);
    }
  }, [showInputBox]);

  return (
    <Fragment>
      {(showAlert || showConfirm || showInputBox) && (
        <div className="functionmodal-container">
          {showAlert && (
            <FunctionAlert
              closing={closing}
              text={text}
              okButtonText={okButtonText}
              closeFunctionModal={handleCloseFunctionModal}
            />
          )}
          {showConfirm && (
            <FunctionConfirm
              closing={closing}
              text={text}
              okButtonText={okButtonText}
              cancelButtonText={cancelButtonText}
              confirmOK={handleConfirmOK}
              closeFunctionModal={handleCloseFunctionModal}
            />
          )}
          {showInputBox && (
            <FunctionInputBox
              closing={closing}
              text={text}
              okButtonText={okButtonText}
              cancelButtonText={cancelButtonText}
              inputBoxResult={handleInputBoxResult}
              closeFunctionModal={handleCloseFunctionModal}
              inputEl={inputEl}
              changeResult={changeResult}
              initialValue={initialValue}
              result={result}
            />
          )}
        </div>
      )}
    </Fragment>
  );
};

export default compose(
  connect(
    mapState,
    actions
  )
)(FunctionModals);
