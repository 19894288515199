import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Message, Segment, Table } from 'semantic-ui-react';
import Paging from "../../../app/common/utils/Paging";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { isMobileView } from "../../../app/common/utils/common";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";

import ImportExceptionItem from './ImportExceptionItem';
import { loadImportExceptions } from '../data/statsActions';
import _ from "lodash";


const ActionStatusCode_Initialised = 'Initialised';
const ActionStatusCode_Attempted_Retry = 'Attempted_Retry';
const ActionStatusCode_Completed = 'Completed';
const ActionStatusCode_Failed = 'Failed';

const mapState = state => ({
  loading: state.loadStats.loading,
  importExceptions: state.loadStats.importExceptions,
});

const actions = {
  loadImportExceptions
};

const itemsPerPage = 10;
let totalPages = 0;

class ImportExceptionTableGroup extends Component {
  state = {
    currentPage: 1
  };

  async componentDidMount() {
    await this.props.loadImportExceptions();
  }

  getImportException = () => {
    let { importExceptions, searchText } = this.props;


    if (searchText !== '') {
      searchText = searchText.replace(/["'()-]/g, '').replace(/ /g, '');
      const re = new RegExp(searchText, 'gi');

      importExceptions = importExceptions.filter(
        a =>
          a.FileName.match(re) ||
          a.ReasonForFailure.match(re)
      );

    }

    // let importException = importExceptions.filter(d => d.CompletedInd === 0);
    // importException = [...importException, ...importExceptions.filter(d => d.CompletedInd === 1)];

    const newTotalPages = Math.ceil(importExceptions.length / itemsPerPage);

    if (newTotalPages !== totalPages) {
      this.setState({ currentPage: 1 });
    }

    totalPages = newTotalPages;

    const page = importExceptions.slice(
      (this.state.currentPage - 1) * itemsPerPage,
      this.state.currentPage * itemsPerPage
    );

    return page;
  };


  pageChange = async (activePage, isTop) => {
    this.setState({
      currentPage: activePage
    });
    if (!isTop) {
      window.scrollTo(0, 0);
    }
  };

  handleSort = (clickedColumn) => () => {
    const { column, actions, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        actions: _.sortBy(actions, [clickedColumn]),
        direction: 'ascending',
      });

      return
    }

    this.setState({
      actions: actions.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    })
  };

  render() {
    const {
      loading,
      scrollRef,
      importExceptions
    } = this.props;


    const {
      currentPage,
      column,
      direction
    } = this.state;

    let totalImportExceptions = importExceptions.length;

    return (
      <Fragment>
        {/* LABELS*/ }
        { !loading && (
          <Segment>
            <Label
              color='blue'
              basic
              style={ { marginRight: 10, marginBottom: isMobileView() ? 10 : 0 } }
            >
              { totalImportExceptions } Import Error{ totalImportExceptions === 1 ? '' : 's' }
            </Label>
          </Segment>
        ) }
        {/* PAGING*/ }
        { !loading && totalPages !== 1 && importExceptions.length > 0 && (
          <div style={ { textAlign: 'center' } }>
            <Paging
              totalPages={ totalPages }
              currentPage={ currentPage }
              onPageChange={ this.pageChange }
              isTop
            />
          </div>
        ) }
        {/* NO IMPORT ERRORS*/ }
        { !loading && importExceptions.length === 0 && (
          <Message>
            <Message.Header>No File Import Errors</Message.Header>
          </Message>
        ) }
        <br/>
        {/* EXCEPTIONS TABLE*/ }
        <div style={ { minHeight: 250 } }>
          { loading && <LoadingComponent/> }
          { !loading && importExceptions.length > 0 && (
            <Table celled striped fixed>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    sorted={ column === '>FileName' ? direction : null }
                    onClick={ this.handleSort('FileName') }
                    width={ 6 }
                  >
                    Filename
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={ column === '>ErrorRowNumber' ? direction : null }
                    onClick={ this.handleSort('ErrorRowNumber') }
                    width={ 3 }
                  >
                    Row of Error
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={ column === 'ReasonForFailure' ? direction : null }
                    onClick={ this.handleSort('ReasonForFailure') }
                    width={ 4 }
                  >
                    Reason for Failure
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={ column === 'CreatedOnDate' ? direction : null }
                    onClick={ this.handleSort('CreatedOnDate') }
                  >
                    Created Date
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                { !loading &&
                this.getImportException().map(importException => {
                  return (
                    <ImportExceptionItem
                      key={ importException.FileErrorLogID }
                      importException={ importException }
                      scrollRef={ scrollRef }
                    />
                  );
                }) }
              </Table.Body>
            </Table>
          ) }
        </div>
        <br/>
        { !loading && totalPages !== 1 && importExceptions.length > 0 && (
          <div style={ { textAlign: 'center' } }>
            <Paging
              totalPages={ totalPages }
              currentPage={ currentPage }
              onPageChange={ this.pageChange }
            />
          </div>
        ) }
      </Fragment>
    );
  }
}

export default compose(
  connect(
    mapState,
    actions
  )
)(ImportExceptionTableGroup);
