import React, { Fragment } from "react";
import { Grid } from "semantic-ui-react";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import BarChart from "../charts/BarChart";

const MothersLoadedPerMonthDetails = ({ loading, activeMomsMonthDetails }) => {
  const TotalMothers = [];
  const labels = [];
  const bgcolours = [];

  if (!loading && activeMomsMonthDetails !== null) {
    activeMomsMonthDetails.forEach(x => {
      TotalMothers.push(x.TotalMothers);
      labels.push(x.MnthYearID);
    });
  }


  if (TotalMothers.length >= 0) {
    bgcolours.push("rgba(255, 102, 196, 0.55)");
  }
  // if (AmountClaimed.length >= 0) {
  //   bgcolours.push("rgba(56, 182, 255, 0.55)");
  // }
  // if (AmountExpired.length >= 0) {
  //   bgcolours.push("rgba(224, 28, 33, 0.55)");
  // }


  return (
    <Fragment>
      { loading && <LoadingComponent/> }
      { !loading && (
        <Grid>
          <b style={ { fontSize: "36px" } }>
            {/* {activeMomsMonthDetails.MnthYearID} */ }
          </b>

          <Grid.Column width={ 16 }>
            { TotalMothers.length === 0 && (
              <div
                style={ {
                  color: "silver",
                  textAlign: "center"
                } }
              >
                No stats available
              </div>
            ) }
            { TotalMothers.length !== 0 && (
              <BarChart
                data={ TotalMothers }
                labels={ labels }
                bgcolours={ bgcolours }
                headings="Number Mothers Joined"
                legends="Mothers Joined"
              />
            ) }
          </Grid.Column>
        </Grid>
      ) }
    </Fragment>
  );
};

export default MothersLoadedPerMonthDetails;
