import React, { Component, createRef, Fragment } from 'react';
import { Grid, Sticky } from 'semantic-ui-react';
import { Redirect, Route, Switch } from 'react-router-dom';
import BYYAdminDashboard from '../byyAdminDashboard/BYYAdminDashboard';
import BYYAdminNav from './BYYAdminNav';
import { isMobileView } from '../../../app/common/utils/common';
import { Helmet } from 'react-helmet';
import ReactTooltip from 'react-tooltip';
import BYYAdminActions from "../byyAdminActions/BYYAdminActions";
import BYYAdminDetails from "../byyAdminDetails/BYYAdminDetails";
import BYYAdminPeople from "../byyAdminPeople/BYYAdminPeople";
import ImportException from "../../stats/importException/ImportException";
import BenefitsSummary from '../../stats/benefitsSummary/BenefitsSummary';


class BYYAdminLanding extends Component {
  contextRef = createRef();

  render() {
    const isMobile = isMobileView();
    return (
      <Fragment>
        <Helmet>
          <title>BYY Admin - Admin</title>
        </Helmet>
        <Grid stackable>
          <Grid.Column width={ 4 } style={ { minWidth: '250px' } }>
            <Sticky
              context={ this.contextRef }
              active={ !isMobile }
              offset={ 60 }
              styleElement={ { zIndex: 0 } }
            >
              {/* NAVBAR */ }
              <BYYAdminNav/>

            </Sticky>
          </Grid.Column>
          <Grid.Column width={ 12 }>
            <div ref={ this.contextRef }>
              <Switch>
                <Redirect exact from='/au' to='/au/dashboard'/>
                <Route exact path='/au/dashboard' component={ BYYAdminDashboard }/>
                <Route exact path='/au/people' component={ BYYAdminPeople }/>
                <Route exact path='/au/benefits' component={ BenefitsSummary }/>
                <Route exact path='/au/action' component={ BYYAdminActions }/>
                <Route exact path='/au/importex' component={ ImportException }/>
                <Route exact path='/au/userdetails' component={ BYYAdminDetails }/>
                <Route render={ () => <Redirect to='/NotFound'/> }/>
              </Switch>
            </div>
          </Grid.Column>
        </Grid>
        <ReactTooltip effect='solid'/>
      </Fragment>
    );
  }
}

export default BYYAdminLanding;
